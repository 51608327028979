
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #888888;
  line-height: 26px;
}
/* 
@font-face {
  font-family: "futur";
  src: url(../fonts/futur.ttf);
  font-weight: normal;
  font-style: normal;
} */
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #111111;
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #888888;
  margin-bottom: 15px;
  line-height: 26px;
  text-align: justify;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
}

*::-moz-selection {
  background: #222;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #222;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #222;
  color: #ffffff;
  text-shadow: none;
}

*::-moz-placeholder {
  color: #222;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #222;
  font-size: 14px;
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/*----------------------------------------
    Body Overlay 
-----------------------------------------*/
.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.body-overlay:hover {
  cursor: pointer;
}

.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.custom-container {
  max-width: 1200px;
}

/*----------------------------------------
    Progress Wrap
-----------------------------------------*/
@media (max-width: 575px) {
  .progress-wrap {
    right: 15px;
    bottom: 15px;
  }
}

/*----------------------------------------*/
/*  02. Background CSS START
/*----------------------------------------*/
/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: #FBFBFB;
}

.grey-bg-2 {
  background: #f3f3f3;
}

.grey-bg-3 {
  background: #f2f2f2;
}

.grey-bg-4 {
  background: #f5f5f5;
}

.white-bg {
  background: #ffffff;
}

.black-bg {
  background: #222;
}

.black-bg-5 {
  background: #292929;
}

/* footer bg colors */
.footer-bg {
  background: #1a1a1a;
}

/*----------------------------------------*/
/* 08. section title css start
/*----------------------------------------*/
/*----------------------------------------*/
/*  04. Button CSS START
/*----------------------------------------*/
.b-btn {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  background-color: #222;
  border: 2px solid #222;
  height: 50px;
  line-height: 42px;
  text-align: center;
  padding: 0 40px;
  text-transform: uppercase;
  font-weight: 500;
}
.b-btn:hover {
  background-color: #ffffff;
  color: #222;
}
.b-btn:focus {
  background-color: #222;
  border-color: #222;
  color: #ffffff;
}

.b-btn-2 {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #222;
  background-color: #ffffff;
  border: 2px solid #222;
  height: 50px;
  line-height: 46px;
  text-align: center;
  padding: 0 40px;
  text-transform: uppercase;
  font-weight: 500;
}
.b-btn-2:hover {
  background-color: #222;
  color: #ffffff;
}
.b-btn-2:focus {
  background-color: #ffffff;
  border-color: #222;
  color: #222;
}

.b-btn-3 {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: #ffffff;
  background-color: #db3340;
  border: 2px solid #db3340;
  height: 38px;
  line-height: 32px;
  text-align: center;
  padding: 0 23px;
  text-transform: capitalize;
  font-weight: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.b-btn-3:hover {
  background-color: #222;
  border-color: #222;
  color: #ffffff;
}
.b-btn-3:focus {
  background-color: #db3340;
  border-color: #db3340;
  color: #ffffff;
}

/* caterogy button */
.cat-btn {
  display: inline-block;
  padding: 0 25px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  background-color: #b9a16b;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.cat-btn i {
  margin-right: 5px;
}
.cat-btn:hover {
  background-color: #222;
}

.link-btn {
  font-size: 14px;
  font-weight: 600;
  color: #b9a16b;
  text-transform: capitalize;
}
.link-btn:hover {
  color: #222;
}

.link-btn-2 {
  font-size: 14px;
  font-weight: 600;
  color: #db3340;
  text-transform: capitalize;
  text-decoration: underline;
  transition: 0.3s;
}
.link-btn-2:hover {
  color: #222;
  text-decoration: underline;
}

.link-btn-3 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  transition: 0.3s;
  display: inline-block;
}
.link-btn-3 i {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
.link-btn-3:hover {
  color: #ff8429;
}

/* slider btn */
.slider-btn {
  font-size: 20px;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 300;
}
.slider-btn i {
  margin-left: 5px;
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}
.slider-btn:hover {
  color: #ffffff;
}

/* pulse btn */
.pulse-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #222;
  animation: pulse 2s infinite;
}
.pulse-btn:hover {
  background-color: #222;
  color: #ffffff;
}
.pulse-btn i {
  padding-left: 2px;
}

/* add to cart btn */
.add-to-cart-btn {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  background-color: #222;
  border: 2px solid #222;
  height: 50px;
  line-height: 46px;
  text-align: center;
  padding: 0 40px;
}
.add-to-cart-btn:hover {
  background-color: #ffffff;
  color: #222;
}

.add-to-cart-btn-2 {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 1.2;
  color: #db3340;
  font-weight: 600;
}
.add-to-cart-btn-2::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #db3340;
}
.add-to-cart-btn-2:hover {
  color: #db3340;
}

.select-btn {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  color: #111111;
  transition: 0.4s;
  text-align: center;
}
.select-btn:hover {
  background-color: #ff8429;
  color: #ffffff;
  border-color: #ff8429;
}

.select-btn-3 {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 0 40px;
  font-size: 14px;
  font-weight: 500;
  color: #111111;
  transition: 0.4s;
  text-align: center;
}
.select-btn-3:hover {
  background-color: #ff8429;
  color: #ffffff;
  border-color: #ff8429;
}

.add-cart-btn {
  display: inline-block;
  height: 48px;
  line-height: 44px;
  text-align: center;
  padding: 0 70px;
  color: #ffffff;
  background: #222;
  border: 2px solid #222;
  font-size: 14px;
}
.add-cart-btn:hover {
  background: #ffffff;
  color: #222;
}

.btn-tp {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #ff8429;
  border-color: transparent;
  color: #222;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  padding: 0px 50px;
  border-width: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  border-radius: 8px;
}
.btn-tp::before {
  position: absolute;
  content: "";
  width: 75px;
  height: 75px;
  background-color: #000000;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  z-index: -1;
  opacity: 0.1;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn-tp:hover::before {
  width: 1200px;
  height: 470px;
}
.btn-tp:hover {
  box-shadow: none;
  color: #ffffff;
}

.btn-tp-2 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #ff8429;
  border-color: transparent;
  color: #222;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  line-height: 48px;
  padding: 0px 50px;
  border-width: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  border-radius: 8px;
}
.btn-tp-2::before {
  position: absolute;
  content: "";
  width: 75px;
  height: 75px;
  background-color: #000000;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  z-index: -1;
  opacity: 0.1;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn-tp-2:hover::before {
  width: 1200px;
  height: 470px;
}
.btn-tp-2:hover {
  box-shadow: none;
  color: #ffffff;
}

.btn-d:focus, .btn:focus:active {
  box-shadow: none;
}

.btn-d:hover {
  box-shadow: none;
}

.btn-d:hover::before {
  width: 420px;
  height: 420px;
}

.btn-d-margin {
  margin-top: 40px;
}

.btn-d {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-color: transparent;
  color: #666666;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Archivo", sans-serif;
  height: 50px;
  line-height: 50px;
  padding: 0px 35px;
  border-width: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .btn {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }
}
/* more btn */
.more-btn {
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  color: #db3340;
}
.more-btn i {
  padding-left: 2px;
}

/*----------------------------------------*/
/*  05. Carousel CSS START
/*----------------------------------------*/
/* common carousel css start */
.common-nav .owl-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.common-nav .owl-nav button {
  font-size: 16px;
  color: #bbbbbb;
}
.common-nav .owl-nav button.nav-right {
  margin-left: 20px;
}
.common-nav .owl-nav button:hover {
  color: #222;
}

.common-dot .owl-dots {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
}
.common-dot .owl-dot.active {
  background: #fff;
  border: 2px solid #222;
  height: 12px;
  width: 12px;
  position: relative;
  top: 2px;
}
.common-dot .owl-dot {
  border: 1px solid #cccccc;
  height: 8px;
  background: #cccccc;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/*----------------------------------------*/
/*  06. Modal CSS START
/*----------------------------------------*/
.product__modal {
  max-width: 900px;
  padding: 20px;
  padding-top: 27px;
}
.product__modal-close {
  top: 15px;
  right: 15px;
  z-index: 1;
}
.product__modal-close button {
  color: #ffffff;
  display: block;
  line-height: 36px;
  text-align: center;
  background: #b9a16b;
  width: 38px;
  height: 38px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #b9a16b;
  outline: none;
}
.product__modal-close button:hover {
  background: #ffffff;
  color: #222;
}
.product__modal-wrapper {
  padding: 20px;
}
.product__modal-content h4 {
  font-size: 24px;
  font-weight: 500;
  text-transform: none;
  padding: 0;
  margin: 0;
  padding-right: 65px;
  margin-bottom: 15px;
}
.product__modal-box .nav-tabs {
  border-bottom: 0;
}
.product__modal-box .nav-link {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}
.product__modal-links ul li {
  display: inline-block;
  margin-right: 4px;
}
.product__modal-links ul li a {
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}
.product__modal-links ul li a:hover {
  color: #222;
  background: #b9a16b;
  border-color: #b9a16b;
}
.product__modal .nav-tabs {
  border: none;
  justify-content: space-between;
}
.product__modal .nav-link {
  border: 1px solid #e3e3e3;
  position: relative;
  margin-bottom: 20px;
}
.product__modal .nav-link.active {
  border-color: #b9a16b;
}
.product__modal .nav-link.active::after {
  position: absolute;
  content: "";
  top: -12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 6px solid transparent;
  border-top-color: transparent;
  border-bottom-color: #b9a16b;
}
.product__modal-title {
  font-size: 22px;
  font-weight: 600;
}
.product__modal-stock span {
  font-size: 14px;
  color: #b9a16b;
}
.product__modal-stock span:first-child {
  color: #222;
  padding-right: 5px;
}
.product__modal-add-review span a {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}
.product__modal-add-review span a::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 80%;
  background: #e3e3e3;
}
.product__modal-add-review span a:hover {
  color: #b9a16b;
}
.product__modal-add-review span:last-child a::after {
  display: none;
}
.product__modal-price span {
  font-size: 22px;
  color: #222;
  display: inline-block;
  margin-bottom: 15px;
}

.rating {
  margin-bottom: 5px;
}
.rating-2 {
  margin-bottom: 15px;
}
.rating ul li {
  display: inline-block;
}
.rating ul li a {
  color: #b9a16b;
}
.rating ul li a:hover {
  color: #222;
}
.rating__shop ul li a {
  font-size: 16px;
}

/*----------------------------------------*/
/* 07. section title css start
/*----------------------------------------*/
.section__title {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 12px;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}
.section__title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background-color: #b9a16b;
}
.section__title-line {
  border-bottom: 2px solid #dddddd;
}
.section__title-line-2 {
  padding-bottom: 10px;
}
.section__title-2 {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 12px;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}
.section__title-icon {
  line-height: 1;
}
.section__title-icon img, .section__title-icon svg {
  vertical-align: inherit;
}
.section__title-3 {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  line-height: 1;
}
.section__title-4 {
  font-size: 24px;
}
.section__title-4::after {
  display: none;
}
.section__title-5 {
  font-size: 24px;
  padding-bottom: 0;
}
.section__title-5::after {
  display: none;
}

/* page title area */
.page__title {
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .page__title {
    height: 400px;
  }
}
@media (max-width: 575px) {
  .page__title {
    height: 350px;
  }
}
.page__title-inner h1 {
  font-size: 36px;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 25px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .page__title-inner {
    margin-top: 0px;
  }
}
.page__title-breadcrumb .breadcrumb {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.page__title-breadcrumb .breadcrumb-item {
  font-size: 12px;
  text-transform: capitalize;
  color: #292929;
  font-weight: 500;
}
.page__title-breadcrumb .breadcrumb-item a:hover {
  color: #ff8429;
}
.page__title-breadcrumb .breadcrumb-item.active {
  color: #333;
}
.page__title-breadcrumb .breadcrumb-item.active::before {
  color: #292929;
}

/*----------------------------------------*/
/*  09. Animation CSS START
/*----------------------------------------*/
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
/* 35. ========= video-ripple ========== */
@keyframes video-ripple {
  0%, 35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@-webkit-keyframes video-ripple {
  0%, 35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
/*----------------------------------------*/
/*  10. HEADER CSS START
/*----------------------------------------*/
.header__top-info {
  padding: 2px 0 4px;
}
.header__top-info ul li {
  display: inline-block;
  position: relative;
}
.header__top-info ul li:not(:last-child) {
  padding-right: 8px;
  margin-right: 8px;
}
.header__top-info ul li:last-child::after {
  display: none;
}
.header__top-info ul li::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.15);
}
.header__top-info ul li  {
  font-size: 13px;
  color: #f3f3f3;
  font-weight: 500;
  margin-bottom: 0;
}
.header__top-info ul li span{margin-right:8px;}
.header__top-info ul li p a {
  font-weight: 500;
  position: relative;
  font-size: 14px;
  color: #f3f3f3;
}
.header__top-info ul li p a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}
.header__top-2 {
  padding: 3px 0;
  border-bottom: 1px solid #e8e8e8;
}
.header__top-menu ul li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.header__top-menu ul li a {
  font-size: 12px;
  font-weight: 300;
  color: #777777;
}
.header__top-menu ul li a:hover {
  color: #222;
}
.header__top-menu ul li.has-dropdown > a {
  padding-right: 11px;
}
.header__top-menu ul li.has-dropdown > a::after {
  position: absolute;
  content: "\f107";
  right: 0;
  top: 1px;
  font-size: 12px;
  color: #777777;
  font-family: "Font Awesome 5 Pro";
}
.header__top-menu ul li.has-dropdown:hover a::after {
  color: #222;
}
.header__top-features {
  display: flex;
  margin-left: 45px;
  position: relative;
  top: 8px;
}
.header__top-features::before {
  position: absolute;
  content: "";
  background: #e5e5e5;
  width: 1px;
  height: 100%;
  top: -8px;
  left: -45px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .header__top-features-3 {
    display: none;
  }
}
.header__social ul li {
  display: inline-block;
  margin-right: 20px;
}
.header__social ul li a {
  color: #555555;
}
.header__social ul li a i {
  color: #b9a16b;
}
.header__social ul li a:hover {
  color: #222;
}
.header__select-item .nice-select {
  border: 0;
  height: inherit;
  line-height: inherit;
  padding-left: 0;
  padding-right: 11px;
}
.header__select-item .nice-select::after {
  border-color: #777777;
  border-width: 1px;
  right: 0;
}
.header__select-item .nice-select .list {
  left: auto;
  right: 0;
  border-radius: 0;
}
.header__select-item .nice-select .list-2 {
  right: -30px;
}
.header__select-item-2 span {
  color: #777777;
}
.header__select-item-2 .nice-select {
  margin-left: 4px;
  float: none;
}
.header__select-item-2 .nice-select .current {
  color: #333;
}
.header__select-item-3 {
  display: flex;
}
.header__select-item-3 .country-flag {
  margin-right: 5px;
}
.header__select-item-3 .nice-select {
  background: transparent;
  color: #111111;
}
.header__select-item-4 nice-select .list {
  right: -30px;
}
.header__select-2 {
  position: relative;
}
.header__select-2::before {
  position: relative;
  content: "";
  width: 1px;
  height: 15px;
  left: -30px;
  top: 0px;
  background: #77777778;
  display: inline-block;
}
.header__search-input {
  position: relative;
  width: 250px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__search-input {
    width: 230px;
  }
}

.header__search-input input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  padding-right: 50px;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.header__search-input input::placeholder {
  color: #c2c2c2;
  font-size: 14px;
}
.header__search-input input:focus {
  border-color: #222;
}
.header__search-input button {
  font-size: 18px;
  color: #333;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header__search-input-2 {
  position: relative;
}
.header__search-input-2 input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  color: #222;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 13px;
  padding-right: 165px;
}
.header__search-input-2 input::placeholder {
  color: #aaaaaa;
}
.header__search-input-2 button {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 17px;
  color: #333;
}
.header__search-input-2 .nice-select {
  position: absolute;
  padding: 0;
  padding-right: 15px;
  height: 40px;
  line-height: 40px;
  border: 0;
  top: -2px;
  right: 60px;
  background-color: transparent;
}
.header__search-input-2 .nice-select::after {
  margin-top: -2px;
  width: 5px;
  height: 5px;
  right: 0;
  border-width: 1px;
  border-color: #777777;
}
.header__search-input-2 .nice-select .current {
  font-size: 13px;
  color: #222;
}
.header__search-input-3 {
  position: relative;
}
.header__search-input-3 input {
  padding-left: 165px;
  padding-right: 55px;
}
.header__search-input-3 button {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 17px;
  color: #333;
}
.header__search-input-3 .nice-select {
  position: absolute;
  padding: 0;
  padding-right: 15px;
  height: 40px;
  line-height: 40px;
  border: 0;
  top: 0px;
  left: 20px;
  background-color: transparent;
  width: 130px;
}
.header__search-input-3 .nice-select::after {
  margin-top: -3px;
  width: 7px;
  height: 7px;
  left: 100px;
  border-width: 1px;
  border-color: #333;
}
.header__search-input-3 .nice-select::before {
  position: relative;
  content: "";
  width: 1px;
  height: 15px;
  right: -125px;
  top: 4px;
  background: #77777778;
  display: inline-block;
}
.header__search-input-3 .nice-select .current {
  font-size: 13px;
  color: #222;
}
.header__action ul li {
  display: inline-block;
}
.header__action ul li:not(:last-child) {
  margin-right: 20px;
}
.header__action ul li a {
  display: inline-block;
  font-size: 25px;
  color: #222;
  position: relative;
}
.header__action ul li a .cart-count {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  width: 19px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background-color: #db3340;
  font-weight: 500;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.header__action ul li a .cart-price {
  font-size: 14px;
  color: #444444;
  margin-left: 20px;
}
.header__action ul li a .flaticon-random-button {
  position: relative;
  top: 4px;
}
.header__action-2 ul li a .cart-count {
  background-color: #ff8429;
}
.header__action-2 ul li .cart-price {
  color: #111111;
  font-weight: 600;
  margin-left: 10px;
}
.header__border {
  border-bottom: 1px solid #e5e5e5;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__border {
    padding: 8px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__middle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.header__middle-2 {
  padding-top: 20px;
  padding-bottom: 10px;
}
.header__middle .sd-contact {
  margin-right: 80px;
}
.header__middle .sd-contact span {
  font-size: 14px;
}
.header__middle .sd-contact span a {
  color: #ff8429;
  font-weight: 500;
}
.header__middle-right-2 {
  display: flex;
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .header__middle-right-2 {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .header__middle-right-d {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .header__bottom {
    padding: 10px 0;
    min-height: 145px;
  }

}
.header__bottom-offer p {
  color: #ffffff;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 0;
  padding: 10px 0;
}
.header__bottom-offer p span {
  font-weight: 500;
}
.header__bottom-offer-border {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}
@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .header__bottom-offer-border {
    border-right: 0;
  }
}
.header__purchase p {
  font-size: 14px;
  color: #282828;
  margin-bottom: 0;
}

.mobile-header-area {
  background: #ffffff;
}

/* main menu css start */
.main-menu-border {
  padding-right: 35px;
  margin-right: 35px;
  border-right: 1px solid #e5e5e5;
}
.main-menu ul li {
  display: inline-block;
  position: relative;
}
.main-menu ul li:not(:last-child) {
  margin-right: 15px;
}
.main-menu ul li.has-dropdown > a {
  padding-right: 14px;
}
.main-menu ul li.has-dropdown > a::after {
  position: absolute;
  content: "\f107";
  right: 0;
  top: 9px;
  font-size: 14px;
  color: #333;
  font-family: "Font Awesome 5 Pro";
}
.main-menu ul li a {
  display: inline-block;
  font-size: 14px;
  color: #333;
  padding: 7px 0;
  position: relative;
}
.main-menu ul li .submenu {
  text-align: left;
  position: absolute;
  min-width: 230px;
  background: #ffffff;
  padding: 15px 0;
  top: 120%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  z-index: 11;
}
.main-menu ul li .submenu li {
  margin: 0;
  display: block;
}
.main-menu ul li .submenu li.has-dropdown > a::after {
  right: 15px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #999999;
}
.main-menu ul li .submenu li > a {
  display: block;
  color: #999999;
  line-height: 1.5;
  padding: 10px 28px;
}
.main-menu ul li .submenu li > a:hover {
  color: #222;
}
.main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.main-menu ul li .submenu li:hover > a::after {
  color: #333;
}
.main-menu ul li:hover > a {
  color: #b9a16b;
}
.main-menu ul li:hover > a::after {
  color: #b9a16b;
}
.main-menu ul li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.main-menu-2 ul li a {
  font-size: 14px;
  color: #282828;
  font-weight: 500;
  padding-bottom: 10px;
}
.main-menu-2 ul li:hover > a {
  color: #db3340;
}
.main-menu-2 ul li.has-dropdown:hover > a::after {
  color: #db3340;
}
.main-menu-3 ul li {
  margin-right: 30px;
}
.main-menu-3 ul li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  padding: 15px 0;
}
.main-menu-3 ul li:hover > a {
  color: #ff8429;
}
.main-menu-3 ul li.has-dropdown > a::after {
  right: -3px;
  top: 16px;
  color: #ffffff;
}

/* vategroy menu css start */
.category__menu {
  position: relative;
}
.category__menu ul li {
  display: inline-block;
  margin-right: 20px;
}
.category__menu ul li:last-child {
  margin-right: 0;
}
.category__menu ul li a {
  display: inline-block;
  font-weight: 500;
  color: #333;
  padding: 24px 0;
}
.category__menu ul li:hover > a {
  color: #b9a16b;
}

.category__btn {
  padding-bottom: 16px;
  padding-top: 18px;
}
.category__btn:hover .side-submenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

/* side submenu */
.side-submenu {
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 120%;
  min-width: 270px;
  z-index: 111;
  visibility: hidden;
  opacity: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side-submenu {
    min-width: 160px;
  }
}
.side-submenu ul li {
  position: relative;
  display: block;
  margin-right: 0;
}
.side-submenu ul li > a {
  display: inline-block;
  width: 100%;
  color: #888888;
  padding: 14px 30px;
  padding-bottom: 10px;
}
.side-submenu ul li > a i {
  font-size: 17px;
  padding-right: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .side-submenu ul li > a {
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side-submenu ul li > a {
    padding-left: 15px;
  }
}
.side-submenu ul li:last-child a {
  border-bottom: none;
}
.side-submenu ul li.has-dropdown > a {
  position: relative;
  padding-right: 15px;
}
.side-submenu ul li.has-dropdown > a::after {
  position: absolute;
  content: "\f0da";
  font-weight: 700;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  color: #888888;
}
.side-submenu ul li:hover > a {
  color: #222;
  background: #f3f3f3;
}
.side-submenu ul li:hover > a::after {
  color: #222;
}
.side-submenu ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  left: 100%;
}
.side-submenu ul li:hover .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.side-submenu ul li .mega-menu {
  position: absolute;
  left: 120%;
  top: 0;
  width: 800px;
  background: #ffffff;
  z-index: 1;
  padding: 25px 25px;
  padding-bottom: 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side-submenu ul li .mega-menu {
    width: 540px;
    left: 110%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .side-submenu ul li .mega-menu {
    width: 650px;
    left: 110%;
  }
}
.side-submenu ul li .mega-menu li {
  width: 30%;
  float: left;
  margin-bottom: 25px;
  display: inline-block;
}
.side-submenu ul li .mega-menu li:not(:last-child) {
  margin-right: 0;
}
.side-submenu ul li .mega-menu li > a {
  font-size: 16px;
  color: #222;
  margin-bottom: 15px;
  padding: 0;
  border: none;
}
.side-submenu ul li .mega-menu li .mega-item {
  margin-bottom: 45px;
}
.side-submenu ul li .mega-menu li .mega-item li {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}
.side-submenu ul li .mega-menu li .mega-item li a {
  font-size: 14px;
  color: #888888;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  width: auto;
}
.side-submenu ul li .mega-menu li .mega-item li a::after {
  position: absolute;
  content: "";
  left: auto;
  right: 0;
  bottom: 0;
  background: #888888;
  width: 0;
  height: 1px;
}
.side-submenu ul li .mega-menu li .mega-item li:hover > a {
  color: #222;
}
.side-submenu ul li .mega-menu li .mega-item li:hover > a::after {
  width: 100%;
  left: 0;
  right: auto;
}
.side-submenu ul li .mega-menu li:hover > a {
  color: #222;
  background-color: inherit;
}
.side-submenu ul li .submenu {
  position: absolute;
  top: 0;
  left: 120%;
  width: 270px;
  background: #ffffff;
  -webkit-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .side-submenu ul li .submenu {
    width: 235px;
  }
}
.side-submenu ul li .submenu li {
  display: block;
  margin-right: 0;
  border-bottom: 1px solid #e7e7e7;
}
.side-submenu ul li .submenu li:last-child {
  border-bottom: none;
}
.side-submenu ul li .submenu li > a {
  width: 100%;
  display: block;
  padding: 15px 25px;
  text-transform: capitalize;
  border-bottom: none;
}
.side-submenu ul li .submenu li > a::before {
  position: absolute;
  content: "";
  left: 0;
  top: auto;
  bottom: 0;
  width: 2px;
  height: 0;
  background: #888888;
}
.side-submenu ul li .submenu li > a::after {
  right: 15px;
  -webkit-transform: translateY(-50%) rotate(0deg);
  -moz-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
}
.side-submenu ul li .submenu li:hover > a::before {
  height: 100%;
  bottom: auto;
  top: 0;
}
.side-submenu ul li .submenu li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  left: 100%;
}
.side-submenu ul li .submenu li .submenu {
  top: 0;
  left: 120%;
  visibility: hidden;
  opacity: 0;
}

.offcanvas__area::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.offcanvas__area .modal.show .modal-dialog {
  transform: none !important;
}
.offcanvas__area .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(100px, 0px);
}
.offcanvas__area .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: #ffffff;
}
@media (max-width: 575px) {
  .offcanvas__area .modal-dialog {
    max-width: 300px;
  }
}
.offcanvas__area .modal-content {
  border: 0;
}
.offcanvas__area-2 .modal.show .modal-dialog {
  transform: none !important;
  left: 0;
}
.offcanvas__area-2 .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(-100px, 0px);
}
.offcanvas__area-2 .modal-dialog {
  left: 0;
  right: auto;
}
.offcanvas__close-btn {
  font-size: 16px;
  color: #222;
  background: transparent;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: transparent;
  border: 0;
  outline: 0;
}
.offcanvas__close-btn:hover {
  color: #b9a16b;
}
.offcanvas__close-btn svg {
  width: 22px;
  height: 22px;
}
.offcanvas__close-btn svg path {
  fill: #222;
}
.offcanvas__action a {
  font-size: 16px;
  color: #222;
  position: relative;
  margin-right: 20px;
}
.offcanvas__action a.has-tag {
  padding-right: 15px;
}
.offcanvas__action a .tag {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  background: #ccc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.offcanvas__action a svg {
  width: 20px;
  height: 22px;
}
.offcanvas__action a svg path {
  fill: #222;
}
.offcanvas__action a:hover .tag {
  background: #222;
  color: #ffffff;
}
.offcanvas__top {
  padding: 40px;
  padding-bottom: 0;
}
.offcanvas__bottom {
  margin-top: 15px;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px 35px;
  background: #ffffff;
  z-index: 1;
}
.offcanvas__search-btn {
  background: transparent;
  font-size: 22px;
}
.offcanvas__search-btn svg {
  width: 22px;
  height: 22px;
}
.offcanvas__search-btn svg path {
  fill: #222;
}
.offcanvas__wrapper {
  position: relative;
  height: 100%;
}
.offcanvas__social ul li {
  display: inline-block;
}
.offcanvas__social ul li:not(:last-child) {
  margin-right: 20px;
}
.offcanvas__social ul li a {
  font-size: 14px;
  color: #222;
}
.offcanvas__content {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.sidebar__search {
  position: relative;
  padding: 0 40px;
}
.sidebar__search input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-right: 30px;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #e3e3e3;
  font-size: 14px;
}
.sidebar__search input::placeholder {
  color: #222;
}
.sidebar__search input:focus {
  border-color: #b9a16b;
}
.sidebar__search button {
  position: absolute;
  top: 50%;
  right: 45px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  color: #222;
}

.header-bar-btn {
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
}
.header-bar-btn span {
  display: inline-block;
  width: 100%;
  background: #222;
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.header-bar-btn span:nth-child(1) {
  top: 0px;
}
.header-bar-btn span:nth-child(2) {
  top: 7px;
}
.header-bar-btn span:nth-child(3) {
  top: 14px;
}
.header-bar-btn-2 span {
  background: #ffffff;
}

/*----------------------------------------*/
/*  11. MEAN MENU CSS START
/*----------------------------------------*/
/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: #222;
  border-top: 1px solid #ebebeb;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}
.mean-container .mean-nav ul li a:hover {
  color: #b9a16b;
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid #ebebeb !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #222;
  line-height: 30px;
  top: 0;
  font-weight: 400;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #b9a16b;
  color: #ffffff;
  border-color: #b9a16b;
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: #222;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: #222;
}

/*----------------------------------------*/
/*  12. SLIDER CSS START
/*----------------------------------------*/
.slider__padding {
  padding-left: 145px;
  padding-right: 145px;
}
.slider__height {
  min-height: 425px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider__padding {
    padding-left: 50px;
    padding-right: 50px;
  }
  .slider__height {
    min-height: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__padding {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .slider__padding {
    padding-left: 0;
    padding-right: 0;
  }
}
.slider__height {
  min-height: 425px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .slider__height {
    min-height: 425px;
  }
}
.slider__title {
  font-size: 72px;
  font-weight: 400;
  color: #ffffff;
 
}
@media (max-width: 575px) {
  .slider__title {
    font-size: 45px;
  }
}
.slider__content span {
  display: inline-block;
  font-size: 12px;
  background-color: #222;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  height: 26px;
  line-height: 26px;
  padding: 0 17px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 25px;
}
.slider__content-2 {
  padding-left: 10px;
}
.slider__content-3 span {
  background-color: #ff8429;
}
.slider__content-3 p {
  font-size: 20px;
  color: #ffffff;
}

.main-slider-pagination {
  position: absolute;
  right: 300px;
  bottom: 40px;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-pagination {
    right: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-pagination {
    right: 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-pagination {
    right: 50px;
  }
}
@media (max-width: 575px) {
  .main-slider-pagination {
    right: 30px;
  }
}
.main-slider-pagination button {
  font-size: 26px;
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.25);
}
.main-slider-pagination button.main-slider-button-prev {
  margin-right: 5px;
}
.main-slider-pagination button:hover {
  background-color: #000;
}
.main-slider-pagination-2 {
  position: static;
}
.main-slider-pagination-2 button {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-pagination-2 button {
    top: 70%;
    -webkit-transform: translateY(-70%);
    -moz-transform: translateY(-70%);
    -ms-transform: translateY(-70%);
    transform: translateY(-70%);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-pagination-2 button {
    top: 80%;
    -webkit-transform: translateY(-80%);
    -moz-transform: translateY(-80%);
    -ms-transform: translateY(-80%);
    transform: translateY(-80%);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-pagination-2 button {
    top: 90%;
    -webkit-transform: translateY(-90%);
    -moz-transform: translateY(-90%);
    -ms-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}
@media (max-width: 575px) {
  .main-slider-pagination-2 button {
    display: none;
  }
}
.main-slider-pagination-2 button.main-slider-button-prev {
  margin-right: 0;
  left: 30px;
  right: auto;
}

/*----------------------------------------*/
/*  13. CATEGORY CSS START
/*----------------------------------------*/
.category__item:hover .category__thumb img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.category__title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.category__title a:hover {
  color: #b9a16b;
}
.category__content {
  padding: 15px 0;
}
.category__quantity {
  font-size: 13px;
  font-weight: 500;
  color: #b9a16b;
}

/*----------------------------------------*/
/*  06. PRODUCT CSS START
/*----------------------------------------*/
.product__thumb {
  position: relative;
}
.product__action {
  position: absolute;
  top: 15px;
  right: -15px;
  visibility: hidden;
  opacity: 0;
}
.product__action ul li {
  margin-bottom: 5px;
}
.product__action ul li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.product__action ul li a svg {
  width: 14px;
  height: 14px;
}
.product__action ul li a:hover {
  color: #ffffff;
  background-color: #222;
}
.product__action ul li a:hover svg path {
  fill: #ffffff;
}
.product__action-2 ul li a {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.product__action-3 {
  top: 70px;
}
.product__action-3 ul li a {
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: #282828;
}
.product__item {
  /* trending slider */
}
.product__item:hover .product__thumb img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.product__item:hover .product__action {
  visibility: visible;
  opacity: 1;
  right: 15px;
}
.product__item-slider .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
@media (max-width: 575px) {
  .product__item-slider .owl-nav {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__item-wrapper {
    margin-top: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__item-wrapper-2 {
    margin-bottom: 40px;
  }
}
.product__item-wrapper-4 {
  margin-left: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .product__item-wrapper-4 {
    margin-left: 10px;
  }
}
.product__item-trending-slider .owl-nav {
  position: absolute;
  top: -75px;
  right: 0;
}
@media (max-width: 575px) {
  .product__item-trending-slider .owl-nav {
    display: none;
  }
}
.product__item-3:hover .product__thumb-3 img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.product__item-3:hover .product__add-cart-3 {
  top: -28px;
}
.product__item-3:hover:hover .product__action {
  visibility: visible;
  opacity: 1;
  right: 15px;
  top: 70px;
}
.product__item-slider-2 .owl-nav {
  display: flex;
  position: absolute;
  top: -75px;
  right: 0;
}
.product__tag a {
  display: inline-block;
  font-size: 12px;
  color: #bababa;
  text-transform: uppercase;
  font-weight: 500;
}
.product__tag a:hover {
  color: #222;
}
.product__tag-2 a {
  display: inline-block;
  font-size: 12px;
  color: #bababa;
  text-transform: uppercase;
  font-weight: 500;
}
.product__tag-2 a:hover {
  color: #222;
}
.product__tag-3 a {
  font-size: 11px;
  color: #db3340;
  text-transform: uppercase;
  display: inline-block;
}
.product__tag-4 span a {
  color: #ff8429;
}
.product__flash {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.product__flash span {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  padding: 0 14px;
  background-color: #b9a16b;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.product__flash-3 {
  position: absolute;
  top: 10px;
  right: 10px;
}
.product__flash-3 span {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #db3340;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
}
.product__flash-4 {
  position: absolute;
  top: 10px;
  left: 10px;
}
.product__flash-4 span {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #ff8429;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
}
.product__title {
  font-size: 13px;
  color: #464646;
  margin-bottom: 3px;
  font-weight: 500;
}
.product__title a:hover {
  color: #b9a16b;
}
.product__title-2 {
  font-size: 16px;
  color: #222;
  margin-bottom: 3px;
}
.product__title-2 a:hover {
  color: #b9a16b;
}
.product__title-3 {
  font-size: 14px;
  font-weight: 500;
  color: #222;
  margin-bottom: 0;
}
.product__title-3 a:hover {
  color: #db3340;
}
.product__price .price {
  font-size: 16px;
  color: #b9a16b;
  font-weight: 600;
}
.product__price .price.old-price {
  font-size: 15px;
  color: #cccccc;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 4px;
}
.product__price .price.new-price {
  font-size: 18px;
  font-weight: 600;
  color: #b9a16b;
}
.product__price-2 .price {
  font-size: 16px;
  color: #b9a16b;
  font-weight: 600;
}
.product__price-2 .price.old-price {
  font-size: 15px;
  color: #cccccc;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 4px;
}
.product__price-2 .price.new-price {
  font-size: 18px;
  font-weight: 600;
  color: #b9a16b;
}
.product__price-3 {
  height: 20px;
}
.product__price-3 .price {
  position: relative;
  font-size: 14px;
  color: #555555;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.product__price-3 .old-price {
  font-size: 13px;
  color: #aaaaaa;
  text-decoration: line-through;
  margin-right: 5px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.product__price-4 .price {
  color: #555555;
  font-weight: 400;
}
.product__sale-slider .owl-nav {
  position: absolute;
  top: -60px;
  right: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@media (max-width: 575px) {
  .product__sale-slider .owl-nav {
    display: none;
  }
}
.product__sale-slider .owl-nav button {
  font-size: 20px;
  color: #bbbbbb;
}
.product__sale-slider .owl-nav button.nav-right {
  margin-left: 25px;
}
.product__sale-slider .owl-nav button:hover {
  color: #222;
}
.product__sale-wrapper {
  padding: 30px 30px 35px;
  border: 2px solid #b9a16b;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (max-width: 575px) {
  .product__sale-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.product__sale-item:hover .product__sale-thumb img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.product__sale-item:hover .product__action {
  visibility: visible;
  opacity: 1;
  right: 15px;
}
.product__sale-tag {
  display: inline-block;
  font-size: 12px;
  color: #bababa;
  text-transform: uppercase;
  font-weight: 500;
}
.product__sale-flash {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.product__sale-flash span {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  padding: 0 14px;
  background-color: #b9a16b;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.product__sale-title {
  font-size: 16px;
  color: #222;
  margin-bottom: 3px;
}
.product__sale-title a:hover {
  color: #b9a16b;
}
.product__sale-price .price {
  font-size: 16px;
  color: #222;
  font-weight: 500;
}
.product__sale-price .price.old-price {
  font-size: 15px;
  color: #cccccc;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 4px;
}
.product__sale-price .price.new-price {
  font-size: 18px;
  font-weight: 600;
  color: #b9a16b;
}
.product__content {
  padding-top: 5px;
}
.product__countdown {
  text-align: center;
}
.product__countdown .cdown {
  display: inline-block;
  height: 60px;
  width: 60px;
  background: #ffffff;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding-top: 10px;
}
.product__countdown .cdown .time-count {
  font-size: 22px;
  font-weight: 600;
  color: #222;
  line-height: 1;
}
.product__countdown .cdown p {
  color: #999999;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}
.product__countdown-2 .cdown {
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7;
}
.product__border {
  border-top: 1px solid #e5e5e5;
}
@media (max-width: 575px) {
  .product__tab {
    margin-top: 30px;
  }
}
.product__tab .nav-tabs {
  padding: 0;
  margin: 0;
  border: 0;
}
.product__tab .nav-tabs .nav-item .nav-link {
  font-size: 13px;
  color: #888888;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  margin-left: 25px;
  background: transparent;
}
.product__tab .nav-tabs .nav-item .nav-link.active {
  background: transparent;
  color: #222;
}
.product__tab .nav-tabs .nav-item-2 .nav-link.active {
  color: #db3340;
  position: relative;
  transition: 0.3s;
  font-weight: 500;
}
.product__tab .nav-tabs .nav-item-2 .nav-link.active::before {
  position: absolute;
  content: "";
  background-color: #db3340;
  width: 100%;
  height: 3px;
  bottom: -12px;
}
.product__tab .nav-tabs .nav-item-2 .nav-link {
  position: relative;
  font-weight: 500;
}
.product__tab .nav-tabs .nav-item-2 .nav-link::before {
  position: absolute;
  content: "";
  background-color: #db3340;
  width: 0;
  height: 3px;
  bottom: -12px;
  transition: 0.3s;
}
.product__tab .nav-tabs .nav-item-2 .nav-link:hover::before {
  width: 100%;
}
.product__tab .nav-tabs .nav-item-2 .nav-link:hover {
  color: #db3340;
}
@media (max-width: 575px) {
  .product__tab .nav-tabs .nav-item-2 {
    margin-bottom: 30px;
  }
}
.product__tab .nav-tabs .nav-item-3 .nav-link.active {
  color: #111111;
  position: relative;
  transition: 0.3s;
  font-weight: 500;
}
.product__tab .nav-tabs .nav-item-3 .nav-link.active::before {
  position: absolute;
  content: "";
  background-color: #111111;
  width: 100%;
  height: 3px;
  bottom: -17px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__tab .nav-tabs .nav-item-3 .nav-link.active::before {
    bottom: -11px;
  }
}
@media (max-width: 575px) {
  .product__tab .nav-tabs .nav-item-3 .nav-link.active::before {
    bottom: -12px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 500px) {
  .product__tab .nav-tabs .nav-item-3 .nav-link.active::before {
    display: none;
  }
}
.product__tab .nav-tabs .nav-item-3 .nav-link {
  position: relative;
  font-weight: 500;
}
.product__tab .nav-tabs .nav-item-3 .nav-link::before {
  position: absolute;
  content: "";
  background-color: #111111;
  width: 0;
  height: 3px;
  bottom: -17px;
  transition: 0.3s;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__tab .nav-tabs .nav-item-3 .nav-link::before {
    bottom: -12px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 500px) {
  .product__tab .nav-tabs .nav-item-3 .nav-link::before {
    display: none;
  }
}
.product__tab .nav-tabs .nav-item-3 .nav-link:hover::before {
  width: 100%;
}
.product__tab .nav-tabs .nav-item-3 .nav-link:hover {
  color: #111111;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__tab .nav-tabs .nav-item-3 {
    margin-top: 20px;
  }
}
.product__tab-2 {
  float: right;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product__tab-2 {
    float: left;
  }
}
.product__tab-2 .nav-tabs .nav-item .nav-link {
  margin-left: 0;
  margin-right: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .product__tab-2 .nav-tabs .nav-item .nav-link {
    margin-top: 20px;
  }
}
.product__tab-3 .nav-tabs .nav-item .nav-link {
  margin-left: 0;
  margin-right: 45px;
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;
  position: relative;
  transition: 0.3s;
  margin-bottom: 18px;
}
.product__tab-3 .nav-tabs .nav-item .nav-link::before {
  position: absolute;
  content: "";
  background-color: #111111;
  width: 0;
  height: 2px;
  bottom: -19px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__tab-3 .nav-tabs .nav-item .nav-link::before {
    bottom: -10px;
  }
}
.product__tab-3 .nav-tabs .nav-item .nav-link:hover::before {
  width: 100%;
}
@media (max-width: 575px) {
  .product__tab-3 .nav-tabs .nav-item .nav-link {
    margin-bottom: 30px;
  }
}
.product__tab-3 .nav-tabs .nav-item .nav-link.active {
  position: relative;
}
.product__tab-3 .nav-tabs .nav-item .nav-link.active::before {
  width: 100%;
}
.product__hot-title p {
  font-size: 32px;
  margin-bottom: 10px;
  color: #222;
}
.product__hot-title h4 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
}
.product__hot-slider .owl-nav {
  position: absolute;
  top: 50%;
  left: -80px;
  transform: translateY(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__hot-slider .owl-nav {
    left: -20px;
  }
}
@media (max-width: 575px) {
  .product__hot-slider .owl-nav {
    display: none;
  }
}
.product__hot-slider .owl-nav button {
  font-size: 26px;
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 40px;
  text-align: center;
  border: 2px solid #dddddd;
  color: #dddddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 10px 0;
}
.product__hot-slider .owl-nav button.main-slider-button-prev {
  margin-right: 5px;
}
.product__hot-slider .owl-nav button:hover {
  background-color: #000;
  border-color: #000;
}
.product__add-cart-3 {
  position: relative;
  top: 10px;
  background-color: #ffffff;
}
.product__tb-slider {
  position: relative;
}
.product__tb-slider button {
  font-size: 26px;
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 40px;
  text-align: center;
  border: 2px solid #dddddd;
  color: #dddddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.product__tb-slider button.main-slider-button-prev {
  margin-right: 5px;
}
.product__tb-slider button:hover {
  background-color: #000;
  border-color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product__tb-slider button {
    display: none;
  }
}
.product__tb-slider .owl-prev {
  position: absolute;
  top: 50%;
  left: -65px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .product__tb-slider .owl-prev {
    left: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .product__tb-slider .owl-prev {
    left: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .product__tb-slider .owl-prev {
    left: -25px;
  }
}
.product__tb-slider .owl-next {
  position: absolute;
  right: -65px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .product__tb-slider .owl-next {
    right: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .product__tb-slider .owl-next {
    right: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .product__tb-slider .owl-next {
    right: -25px;
  }
}
.product__modal-box .nav-tabs {
  border: 0;
  margin-top: 10px;
}
.product__modal-box .nav-tabs .nav-link {
  padding: 0;
  border: none;
  border: 2px solid transparent;
}
.product__modal-box .nav-tabs .nav-link.active {
  border: 2px solid #b9a16b;
}
.product__modal-nav .nav {
  display: block !important;
}
.product__modal-nav .nav .nav-item {
  opacity: 0.5;
}
.product__modal-nav .nav .nav-item.active {
  opacity: 1;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__modal-content-2 {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .product__modal-content-2 {
    margin-top: 40px;
  }
}
.product__modal-content-2 h4 {
  font-size: 16px;
  margin-bottom: 17px;
  color: #282828;
}
.product__modal-content-2 h4 a:hover {
  color: #ff8429;
}
.product__modal-content-2 .rating ul li {
  display: inline-block;
}
.product__modal-content-2 .rating ul li span {
  font-size: 14px;
  color: #ff8429;
}
.product__modal-content-2 .rating .rating-shop ul {
  display: inline-block;
}
.product__modal-content-2 .rating .rating-shop ul li span {
  font-size: 12px;
  color: #ff8429;
}
.product__modal-content-2 .rating .rating-shop span {
  font-size: 12px;
  font-weight: 500;
}
.product__modal-content-2 .rating-shop {
  display: flex;
}
@media (max-width: 575px) {
  .product__modal-content-2 .rating-shop {
    display: block;
  }
}
.product__modal-content-2 .rating-left {
  position: relative;
  padding-left: 25px;
  margin-left: 25px;
}
@media (max-width: 575px) {
  .product__modal-content-2 .rating-left {
    padding-left: 0;
    margin-left: 0;
    padding-right: 25px;
    margin-right: 25px;
  }
}
.product__modal-content-2 .rating-left::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 12px;
  width: 1px;
  background: #dcdcdc;
}
@media (max-width: 575px) {
  .product__modal-content-2 .rating-left::after {
    display: none;
  }
}
.product__modal-content-2 .rating-left a:hover {
  color: #ff8429;
}
.product__modal-content-2.old-price {
  color: #8c8c8c;
  text-decoration: line-through;
}
.product__modal-content-2 .product__price span {
  font-size: 14px;
  color: #222;
  font-weight: 500;
  margin-right: 7px;
}
.product__modal-content-2 .product__price span.old-price {
  color: #8c8c8c;
  text-decoration: line-through;
}
.product__modal-content-2 .product__modal-des {
  padding-top: 18px;
  padding-bottom: 25px;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.product__modal-content-2 .product__modal-des p {
  font-size: 14px;
  line-height: 24px;
  color: #606060;
  margin-bottom: 0;
}
.product__modal-content-2 .product__modal__tag span {
  font-size: 14px;
  color: #606060;
  display: inline-block;
}
.product__modal-content-2 .product__modal__tag span a:hover {
  color: #ff8429;
}
.product__modal-content-2 .product__share span {
  font-size: 14px;
  color: #6c6c6c;
  margin-right: 20px;
}
.product__modal-content-2 .product__share ul {
  display: inline-block;
}
.product__modal-content-2 .product__share ul li {
  display: inline-block;
  margin-right: 30px;
}
.product__modal-content-2 .product__share ul li a {
  font-size: 14px;
  color: #6c6c6c;
  display: inline-block;
}
.product__modal-content-2 .product__share ul li a:hover {
  color: #ff8429;
}
.product__modal-content-2 .product__details-color span {
  font-size: 14px;
  color: #222;
  width: 15%;
  display: inline-block;
}
@media (max-width: 575px) {
  .product__modal-content-2 .product__details-color span {
    margin-bottom: 15px;
  }
}
.product__modal-content-2 .product__details-color ul {
  line-height: 1;
}
.product__modal-content-2 .product__details-color ul li {
  display: inline-block;
}
.product__modal-content-2 .product__details-color ul li:not(:last-child) {
  margin-right: 5px;
}
.product__modal-content-2 .product__details-color ul li a {
  display: inline-block;
  width: 35px;
  height: 20px;
  background-color: #222;
  box-shadow: 0 0 0 1px #dddddd, inset 0 0 0 2px #fff;
}
.product__modal-content-2 .product__details-color ul li a.brown {
  background-color: brown;
}
.product__modal-content-2 .product__details-color ul li a.blue {
  background-color: blue;
}
.product__modal-content-2 .product__details-color ul li a.red {
  background-color: red;
}
.product__modal-content-2 .product__details-color ul li a.white {
  background-color: white;
}
.product__modal-content-2 .product__details-color ul li a.active, .product__modal-content-2 .product__details-color ul li a:hover {
  box-shadow: 0 0 0 1px #151515, inset 0 0 0 2px #fff;
}
.product__modal-content-2 .product__details-size span {
  font-size: 14px;
  color: #222;
  width: 15%;
  display: inline-block;
}
@media (max-width: 575px) {
  .product__modal-content-2 .product__details-size span {
    margin-bottom: 15px;
  }
}
.product__modal-content-2 .product__details-size ul {
  line-height: 1;
}
.product__modal-content-2 .product__details-size ul li {
  display: inline-block;
}
.product__modal-content-2 .product__details-size ul li:not(:last-child) {
  margin-right: 5px;
}
.product__modal-content-2 .product__details-size ul li a {
  display: inline-block;
  width: 35px;
  height: 20px;
  line-height: 18px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  overflow: hidden;
}
.product__modal-content-2 .product__details-size ul li a.active, .product__modal-content-2 .product__details-size ul li a:hover {
  border-color: #222;
  color: #222;
}
.product__modal-content-2 .product__details-size ul li a.unavailable {
  position: relative;
  cursor: not-allowed;
  color: #e1e1e1;
}
.product__modal-content-2 .product__details-size ul li a.unavailable::after {
  position: absolute;
  content: "";
  left: -3px;
  top: -51px;
  width: 1px;
  height: 142px;
  transform: rotate(60deg);
  background-color: #e1e1e1;
}
.product__modal-content-2 .product__details-size .product-size-guide-btn {
  font-size: 11px;
  font-weight: 500;
  color: #222;
  background-color: transparent;
  outline: 0;
  text-transform: uppercase;
}
.product__sale {
  display: table;
  position: absolute;
  text-align: center;
  top: 36px;
  right: -20px;
  z-index: 2;
  min-width: 100px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.product__sale span {
  position: relative;
  background: #ff8429;
  color: #fff;
  float: left;
  font-size: 11px;
  font-weight: 400;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  min-width: 50px;
  border-radius: 0;
  min-height: auto;
  border: 0;
}
.product__sale span::after {
  border: 5px solid #ff8429;
  border-color: transparent transparent #ff8429 #ff8429;
  border-width: 10px 5px;
  position: absolute;
  right: -10px;
  bottom: 0;
  content: "";
  z-index: 1;
}
.product__sale span::before {
  border: 5px solid #ff8429;
  border-color: #ff8429 transparent transparent #ff8429;
  border-width: 9px 5px;
  position: absolute;
  right: -10px;
  top: 0;
  content: "";
  z-index: 1;
}
.product__sale span.percent {
  background: #222;
}
.product__sale span.percent::after {
  border: 5px solid #222;
  border-color: transparent transparent #222 #222;
  border-width: 10px 5px;
}
.product__sale span.percent::before {
  border: 5px solid #222;
  border-color: #222 transparent transparent #222;
  border-width: 9px 5px;
}
.product__details-tab-nav .nav-tabs {
  border: none;
}
.product__details-tab-nav .nav-tabs .nav-link {
  padding: 0 50px;
  border: 0;
  font-size: 20px;
  color: #a3a3a3;
  font-weight: 500;
}
.product__details-tab-nav .nav-tabs .nav-link.active {
  color: #222;
}
@media only screen and (min-width: 576px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product__details-tab-nav .nav-tabs .nav-link {
    padding: 10px 25px;
  }
}
@media (max-width: 575px) {
  .product__details-tab-nav .nav-tabs .nav-link {
    padding: 0px;
    text-align: left;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.product__details-des p {
  color: #606060;
  font-size: 16px;
  margin-bottom: 28px;
}
.product__details-des-list ul li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
}
.product__details-des-list ul li::after {
  position: absolute;
  content: "\f00c";
  left: 0;
  top: 0;
  font-size: 14px;
  color: #606060;
  font-family: "Font Awesome 5 Pro";
}
.product__details-des-list ul li span {
  font-size: 16px;
  color: #606060;
}
.product__details-review p {
  color: #606060;
  font-size: 16px;
  margin-bottom: 28px;
}
.product__desc-info ul li {
  display: flex;
  align-items: center;
  padding: 15px 30px;
}
@media (max-width: 575px) {
  .product__desc-info ul li {
    padding: 15px 15px;
  }
}
.product__desc-info ul li:nth-child(2n+1) {
  background: #f7f7f7;
}
.product__desc-info ul li h6 {
  font-size: 16px;
  color: #454545;
  margin-bottom: 0;
  display: inline-block;
  width: 25%;
}
@media (max-width: 575px) {
  .product__desc-info ul li h6 {
    width: 60%;
  }
}
.product__desc-info ul li span {
  font-size: 16px;
  color: #454545;
}

/* product cart */
.product-quantity-title label {
  font-size: 14px;
  font-weight: 500;
  color: #606060;
  margin-right: 10px;
}

.cart-plus-minus {
  position: relative;
  display: inline-block;
}

.cart-plus-minus input {
  border: 0px;
  outline: 0px;
  background: none;
  font-weight: 400;
  color: #222;
  font-size: 14px;
  display: inline-block;
  height: 50px;
  list-style: 45px;
  padding: 0 20px;
  width: 110px;
  border: 1px solid #ebebeb;
  text-align: center;
}

.cart-plus-minus .qtybutton {
  font-size: 20px;
  color: #222;
  display: inline-block;
  position: absolute;
  top: 15px;
  height: 22px;
  width: 22px;
  background: transparent;
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.cart-plus-minus .dec {
  left: 7px;
}

.cart-plus-minus .inc {
  right: 7px;
}

.product-widget {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 30px;
}
.product-widget:last-child {
  border-bottom: 0;
}
.product-widget .pt-title {
  font-size: 18px;
  position: relative;
}
.product-widget .cat-link ul li {
  margin-bottom: 15px;
}
.product-widget .cat-link ul li:last-child {
  margin-bottom: 0;
}
.product-widget .cat-link ul li a {
  position: relative;
  font-weight: 400;
  display: block;
}
.product-widget .cat-link ul li a::after {
  position: absolute;
  content: "\f105";
  right: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
}
.product-widget .cat-link ul li a:hover {
  color: #ff8429;
}
.product-widget .cat-link ul li a:hover::after {
  color: #ff8429;
}
.product-widget .size ul li {
  display: inline-block;
  margin-right: 8px;
}
.product-widget .size ul li a {
  display: inline-block;
  font-size: 14px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #222;
  background: #f1f1f1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.product-widget .size ul li a:hover {
  background: #ff8429;
  color: #ffffff;
}
.product-widget .color__pick ul li {
  display: inline-block;
  margin-right: 18px;
}
.product-widget .color__pick ul li button {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 1;
}
.product-widget .color__pick ul li button::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 1px solid #ececec;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
.product-widget .color__pick ul li button.color-1 {
  background: #fbaf5d;
}
.product-widget .color__pick ul li button.color-1:hover::after {
  border-color: #fbaf5d;
}
.product-widget .color__pick ul li button.color-2 {
  background: #1cbbb4;
}
.product-widget .color__pick ul li button.color-2:hover::after {
  border-color: #1cbbb4;
}
.product-widget .color__pick ul li button.color-3 {
  background: #f06eaa;
}
.product-widget .color__pick ul li button.color-3:hover::after {
  border-color: #f06eaa;
}
.product-widget .color__pick ul li button.color-4 {
  background: #004a80;
}
.product-widget .color__pick ul li button.color-4:hover::after {
  border-color: #004a80;
}
.product-widget .color__pick ul li button.color-5 {
  background: #c69c6d;
}
.product-widget .color__pick ul li button.color-5:hover::after {
  border-color: #fbaf5d;
}
.product-widget .color__pick ul li button.color-6 {
  background: #82ca9c;
}
.product-widget .color__pick ul li button.color-6:hover::after {
  border-color: #82ca9c;
}
.product-widget .color__pick ul li button.color-7 {
  background: #252525;
}
.product-widget .color__pick ul li button.color-7:hover::after {
  border-color: #252525;
}
.product-widget .brand ul li {
  margin-bottom: 5px;
}
.product-widget .brand ul li a {
  color: #a3a3a3;
  font-size: 16px;
}
.product-widget .brand ul li a:hover {
  color: #ff8429;
}

.features__product-wrapper:hover .add-cart {
  visibility: visible;
  opacity: 1;
  bottom: 0;
}
.features__product-wrapper:hover .price span {
  visibility: hidden;
  opacity: 0;
}
.features__product-wrapper .add-cart {
  bottom: -10px;
  left: 0;
  visibility: hidden;
  opacity: 0;
}
.features__product-wrapper .add-cart a {
  font-weight: 500;
  color: #222;
  position: relative;
  font-size: 14px;
}
.features__product-wrapper .add-cart a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  height: 1px;
  width: 100%;
  background: #222;
}
.features__product-wrapper .add-cart a:hover {
  color: #ff8429;
}
.features__product-wrapper .add-cart a:hover::after {
  background: #ff8429;
}
.features__product-content h5 {
  font-size: 14px;
  color: #282828;
  margin-bottom: 5px;
  font-weight: 400;
  line-height: 20px;
}
.features__product-content h5 a:hover {
  color: #ff8429;
}
.features__product-content .price {
  position: relative;
}
.features__product-content .price span {
  color: #222;
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
}
.features__product-content .price span.price-old {
  color: #8c8c8c;
  text-decoration: line-through;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .pproduct-sidebar-area {
    margin-right: 0;
  }
}

.pro-price span, del {
  font-size: 16px;
}

/*range slider css start*/
#slider-range {
  position: relative;
}

.ui-widget.ui-widget-content {
  border: none;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.004);
}

.ui-slider-horizontal {
  height: 3px;
}

.ui-widget-content {
  background: #ebebeb;
}

.ui-widget-header {
  background: #222;
}

.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  transform: translateY(-50%);
}

.ui-slider .ui-slider-handle {
  height: 17px;
  width: 3px;
  background: #222222;
  margin-left: 0;
  border-radius: 0;
}

.slider-range p {
  color: #666666;
  margin-bottom: 0;
}

.slider-range p label {
  font-size: 12px;
  font-weight: 400;
  color: #6b6b6b;
}

.slider-range p input {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
}

.slider-range p label {
  margin-right: 3px;
}

.slider-range input {
  font-size: 12px;
  font-weight: 400;
  padding:6px;
  color: #6b6b6b;
  border: none;
  outline: none;
  background: none;
  width: 100px;
}
.price-filter-btn{color: #ffffff !important;}

.product__color ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.product__color ul li {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (max-width: 575px) {
  .product__color ul li {
    margin-bottom: 10px;
  }
}
.product__color ul li:not(:last-child) {
  margin-right: 12px;
}
.product__color ul li a {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 1;
  font-size: 0;
  text-align: center;
  line-height: 20px;
  box-shadow: 0 0 0 1px #e6e6e6, inset 0 0 0 2px #fff;
  background: #000;
}
.product__color ul li a img {
  width: 16px;
  height: 16px;
}
.product__color ul li a.selected {
  box-shadow: 0 0 0 1px #222, inset 0 0 0 2px #fff;
}
.product__color ul li a:hover {
  box-shadow: 0 0 0 1px #222, inset 0 0 0 2px #fff;
}
.product__color ul li a.blue {
  background: blue;
}
.product__color ul li a.red {
  background: red;
}
.product__color ul li a.yellow {
  background: yellow;
}
.product__color ul li a.pink {
  background: pink;
}
.product__color ul li a.brown {
  background: brown;
}
.product__color ul li a.green {
  background: green;
}
.product__color ul li a.oragne {
  background: orange;
}

.single-widget-category:not(:last-child) {
  margin-bottom: 10px;
}
.single-widget-category input {
  display: none;
}
.single-widget-category label {
  color: #666666;
  display: inline-block;
  position: relative;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding-left: 35px;
}
.single-widget-category label:hover {
  color: #ff8429;
  cursor: pointer;
}
.single-widget-category label::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
}
.single-widget-category label::after {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font awesome 5 pro";
  font-size: 12px;
  content: "\f00c";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 200;
}
.single-widget-category input:checked + label {
  color: #ff8429;
}
.single-widget-category input:checked + label::after {
  opacity: 1;
  visibility: visible;
}
.single-widget-category label span {
  color: #bab9b9;
}

.single-widget-category .color-black-bg::before {
  background-color: #222;
  border-color: transparent;
}
.single-widget-category .color-green-bg::before {
  background-color: #309232;
  border-color: transparent;
}
.single-widget-category .color-blue-bg::before {
  background-color: #2237d6;
  border-color: transparent;
}
.single-widget-category .color-red-bg::before {
  background-color: #e21515;
  border-color: transparent;
}
.single-widget-category .color-gray-bg::before {
  background-color: #777;
  border-color: transparent;
}
.single-widget-category .color-black-bg::before {
  background-color: #222;
  border-color: transparent;
}
.single-widget-category .color-white-bg::before {
  background-color: #ffffff;
  border-color: #ddd;
}

.sm-filter-title {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 25px;
  color: #111111;
}

.tagcloud a {
  background: #ffffff;
  border: 1px solid #ebebeb;
  display: inline-block;
  color: #a3a3a3;
  text-decoration: none;
  height: 26px;
  padding: 0 15px;
  font-size: 12px;
  margin-right: 7px;
  margin-bottom: 7px;
  line-height: 26px;
}
.tagcloud a:hover {
  color: #ffffff;
  background-color: #222;
  border-color: transparent;
}

.shop-top-left > span {
  font-size: 14px;
  color: #666666;
  display: inline-block;
  margin-right: 8px;
}

.select-default .nice-select {
  border: 0;
  float: none;
  height: 23px;
  line-height: 23px;
  padding: 0;
  max-width: 168px;
  margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .select-default .nice-select {
    margin-left: auto;
  }
}
@media (max-width: 575px) {
  .select-default .nice-select {
    margin-right: auto;
    margin-left: inherit;
  }
}
.select-default .nice-select::after {
  right: 0;
  color: #666;
}
.select-default .nice-select span {
  color: #666;
  font-size: 14px;
}

.tab-btn-group button {
  padding: 0;
  line-height: 1;
  background: transparent;
  color: #acacac;
  border: 0;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 10px;
}
.tab-btn-group button.active {
  color: #222;
}

.shop-top-right .select-default .list {
  width: 100%;
}

.product-thumb-list {
  max-width: 260px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product-thumb-list {
    max-width: inherit;
    margin-right: 0;
  }
}

.wood-list-product-wrap {
  display: flex;
  align-items: center;
}

.wood-product-list-content {
  margin-left: -15px;
  padding-right: 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .wood-product-list-content {
    margin-left: 0;
    margin-top: 25px;
  }
}
.wood-product-list-content p {
  color: #666666;
  line-height: 30px;
  margin-bottom: 22px;
}
.wood-product-list-content .pro-title {
  margin-bottom: 6px;
  padding-bottom: 0;
}
.wood-product-list-content .pro-title:hover {
  color: #ff8429;
}
.wood-product-list-content .rating {
  margin-bottom: 15px;
}

.wood-cart-btn {
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #f6f6f6;
  height: 43px;
  line-height: 41px;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #222222;
  text-transform: uppercase;
  padding: 0 55px;
}
.wood-cart-btn:hover {
  background-color: #ff8429;
  color: #ffffff;
  border-color: transparent;
}

.wood-proudct-btn-boxed {
  display: inline-block;
  border-radius: 2px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border: 1px solid #e1e1e1;
  vertical-align: middle;
  font-size: 15px;
}
.wood-proudct-btn-boxed:hover {
  background-color: #ff8429;
  color: #ffffff;
  border-color: transparent;
}

.wood-shop-product-actions a {
  margin-right: 6px;
}
@media (max-width: 575px) {
  .wood-shop-product-actions a {
    margin-bottom: 6px;
  }
}

.product-thumb {
  overflow: hidden;
  position: relative;
}
.product-thumb :is(img:nth-child(2)) {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.single-product:hover :is(img:nth-child(2)) {
  opacity: 1;
  visibility: visible;
  transform: scale(1.1);
}

.basic-pagination ul li {
  display: inline-block;
}
.basic-pagination ul li:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 575px) {
  .basic-pagination ul li {
    margin-bottom: 10px;
  }
}
.basic-pagination ul li a {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 42px;
  text-align: center;
  border: 2px solid #e3e3e3;
  font-size: 12px;
  font-weight: 500;
}
.basic-pagination ul li a:hover, .basic-pagination ul li a.active {
  color: #222;
  border-color: #222;
}

.basic-pagination-3 ul li {
  display: inline-block;
  margin-right: 20px;
}
@media (max-width: 575px) {
  .basic-pagination-3 ul li {
    margin-right: 5px;
  }
}
.basic-pagination-3 ul li.prev a, .basic-pagination-3 ul li.next a {
  width: inherit;
  height: inherit;
  line-height: inherit;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}
.basic-pagination-3 ul li.prev a:hover, .basic-pagination-3 ul li.next a:hover {
  color: #ff8429;
  background: inherit;
}
.basic-pagination-3 ul li.active a {
  color: #ffffff;
  background: #ff8429;
  border-color: #ff8429;
}
.basic-pagination-3 ul li a {
  position: relative;
  overflow: hidden;
  background: transparent;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid #eef0f6;
  color: #222;
}
.basic-pagination-3 ul li a:hover {
  background: #ff8429;
  color: #ffffff;
  border-color: #ff8429;
}

.department__image {
  position: relative;
}
.department__product {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15%;
  text-align: center;
  margin: 0 auto;
}
.department__product a {
  display: flex;
  height: 40px;
  width: 200px;
  background: #ffffff;
  border-radius: 3px;
  justify-content: center;
  margin: 0 auto;
  line-height: 38px;
}
.department__product a span {
  font-size: 17px;
  font-weight: 500;
  color: #111111;
  margin-right: 5px;
}
.department__product a p {
  margin-bottom: 0;
  position: relative;
  top: 6px;
}
.department__product-sm-image {
  overflow: hidden;
}
.department__product-sm-image:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}
.department__product-sm-image img {
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

/* common cart btn */
.cart-plus-minus {
  width: 120px;
}

.cart-plus-minus input {
  border: 0px;
  outline: 0px;
  background: none;
  font-weight: 400;
  color: #222;
  font-size: 14px;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 50px;
  width: 100%;
  border: 1px solid #ebebeb;
  text-align: center;
}

.cart-plus-minus .qtybutton {
  font-size: 20px;
  color: #222;
  display: inline-block;
  position: absolute;
  top: 50%;
  height: 22px;
  width: 22px;
  background: transparent;
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cart-plus-minus .dec {
  left: 7px;
}

.cart-plus-minus .inc {
  right: 7px;
}

/*----------------------------------------*/
/*  15. BANNER CSS START
/*----------------------------------------*/
.banner__area-2 {
  padding-left: 145px;
  padding-right: 145px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner__area-2 {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__area-2 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .banner__area-2 {
    padding-left: 0;
    padding-right: 0;
  }
}
.banner__title {
  font-size: 22px;
  font-weight: 600;
}
.banner__title-2 {
  font-size: 34px;
}
@media (max-width: 575px) {
  .banner__title-2 {
    font-size: 20px;
  }
  .banner__title-2 br {
    display: none;
  }
}
.banner__content {
  padding: 65px 70px 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner__content {
    padding: 27px 65px 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner__content {
    padding: 15px 66px 15px;
  }
}
@media (max-width: 575px) {
  .banner__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.banner__content-2 {
  position: absolute;
  top: 70px;
  left: 70px;
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .banner__content-2 {
    top: 15px;
    left: 40px;
  }
}
.banner__content-2 p {
  font-size: 14px;
  color: #db3340;
}
.banner__content-3 {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 450px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  z-index: 1;
}
.banner__content-3::before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: -1;
}
.banner__content-wrapper {
  margin-right: 160px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__content-wrapper {
    margin-right: 50px;
  }
}
@media (max-width: 575px) {
  .banner__content-wrapper {
    margin-right: 0;
  }
}
.banner__content-wrapper h5 {
  font-size: 26px;
  line-height: 1.4;
}
.banner__content-wrapper span {
  font-size: 22px;
  line-height: 1.4;
  display: inline-block;
  color: #111111;
}
.banner__content-wrapper form input {
  background: #ffffff;
  border: 0;
  padding: 0 25px;
  border-radius: 3px;
  min-width: 310px;
}
.banner__content-wrapper form input::-moz-placeholder {
  color: #6c6c6c;
  font-size: 14px;
  opacity: 1;
}
.banner__content-wrapper form input::placeholder {
  color: #6c6c6c;
  font-size: 14px;
  opacity: 1;
}
@media (max-width: 575px) {
  .banner__content-wrapper form input {
    min-width: 100px;
    margin-bottom: 15px;
    height: 40px;
  }
}
@media (max-width: 575px) {
  .banner__content-wrapper form {
    display: inline-block !important;
  }
}
.banner__content-wrapper .save-info {
  display: flex;
  justify-content: center;
}
.banner__content-wrapper .save-info p {
  color: #db3340;
}
.banner__slider-active {
  position: relative;
}
.banner__slider-active button {
  font-size: 26px;
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 40px;
  text-align: center;
  border: 2px solid #dddddd;
  color: #dddddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.banner__slider-active button.main-slider-button-prev {
  margin-right: 5px;
}
.banner__slider-active button:hover {
  background-color: #000;
  border-color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .banner__slider-active button {
    display: none;
  }
}
.banner__slider-active .owl-prev {
  position: absolute;
  top: 50%;
  left: -65px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner__slider-active .owl-prev {
    left: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .banner__slider-active .owl-prev {
    left: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .banner__slider-active .owl-prev {
    left: -25px;
  }
}
.banner__slider-active .owl-next {
  position: absolute;
  right: -65px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner__slider-active .owl-next {
    right: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .banner__slider-active .owl-next {
    right: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .banner__slider-active .owl-next {
    right: -25px;
  }
}
.banner__sd-area {
  min-height: 700px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.banner__sd-area .bakix-marker {
  position: absolute;
}
.banner__sd-area .map-c1 {
  top: 58%;
  left: 9%;
}
.banner__sd-area .map-c2 {
  top: 22%;
  left: 18%;
}
.banner__sd-area .map-c3 {
  bottom: 18%;
  right: 35%;
}
@media (max-width: 575px) {
  .banner__sd-area .map-c3 {
    display: none;
  }
}
.banner__sd-area .map-c4 {
  bottom: 30%;
  right: 10%;
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .banner__sd-area .map-c4 {
    bottom: 32%;
    right: 22%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner__sd-area .map-c4 {
    bottom: 32%;
    right: 18%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner__sd-area .map-c4 {
    right: 20%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner__sd-area .map-c4 {
    bottom: 39%;
    right: 25%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__sd-area .map-c4 {
    bottom: 40%;
    right: 35%;
  }
}
@media (max-width: 575px) {
  .banner__sd-area .map-c4 {
    display: none;
  }
}
.banner__sd-area .map-c5 {
  top: 37%;
  right: 43%;
}
@media (max-width: 575px) {
  .banner__sd-area .map-c5 {
    top: 41%;
    right: 56%;
  }
}
.banner__sd-area .bakix-map-icon {
  background: #ff8429;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  color: #fff;
  position: relative;
}
.banner__sd-area .bakix-map-icon i {
  font-size: 10px;
}
.banner__sd-area .bakix-map-icon::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  animation: video-ripple 1500ms ease-out infinite;
  left: -2px;
  top: -2px;
}
.banner__sd-area .bakix-map-address {
  background: #fff;
  padding: 12px;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  position: absolute;
  z-index: 9;
  width: 200px;
  top: 100%;
}
.banner__sd-area .bakix-map-address p {
  color: #747691;
  margin: 0;
}
.banner__sd-area .bakix-marker:hover .bakix-map-address {
  opacity: 1;
  visibility: visible;
}
.banner__thumb {
  position: relative;
}
.banner__thumb::before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

/*----------------------------------------*/
/*  16. FEATURES CSS START
/*----------------------------------------*/
.features__wrapper {
  border-bottom: 1px solid #3a3a3a;
}
.features__item:hover .features__icon {
  -webkit-transform: translateY(-5px) translate3d(0, -5px, 0);
  -moz-transform: translateY(-5px) translate3d(0, -5px, 0);
  -ms-transform: translateY(-5px) translate3d(0, -5px, 0);
  transform: translateY(-5px) translate3d(0, -5px, 0);
}
.features__item-border {
  position: relative;
}
.features__item-border::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 106px;
  background-color: #e5e5e5;
}
.features__item-2 {
  display: flex;
  justify-content: center;
}
.features__item-2 .features__icon i {
  color: #db3340;
}
.features__item-2 .features__icon-2 {
  margin-bottom: 0;
}
.features__item-2 .features__icon-2 i {
  color: #ff8429;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .features__item-d {
    display: none;
  }
}
.features__icon {
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  -moz-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  -ms-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  -o-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  margin-bottom: 5px;
}
.features__icon i {
  font-size: 45px;
  color: #b9a16b;
}
.features__title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}
.features__title-2 {
  color: #ffffff;
}
.features__title-3 {
  font-weight: 600;
  margin-bottom: 0;
}
.features__content p {
  color: #999999;
}
.features__content-2 p {
  margin-bottom: 0;
}

.features-2__title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.features-2__title-2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.features-2__icon {
  min-height: 60px;
}
.features-2__icon-2 {
  margin-bottom: 25px;
}
.features-2__item {
  padding: 0 92px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .features-2__item {
    padding-left: 52px;
    padding-right: 52px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-2__item {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .features-2__item {
    padding-left: 0;
    padding-right: 0;
  }
}
.features-2__item-br {
  border-right: 1px solid #ebebeb;
}
.features-2__item-bb {
  border-bottom: 1px solid #ebebeb;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .features-2__item-bb {
    padding-top: 40px;
  }
}
.features-2__item-2 {
  padding-left: 45px;
  padding-right: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .features-2__item-2 {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 575px) {
  .features-2__item-2 {
    padding-left: 0;
    padding-right: 0;
  }
}
.features-2__item-pt-65 {
  padding-top: 65px;
}
.features-2__item-pb-40 {
  padding-bottom: 40px;
}
.features-2__content-2 p {
  color: #555555;
}

.brand__area .owl-carousel .owl-item img {
  width: auto;
}
.brand__info h4 {
  font-size: 24px;
}
.brand__info a {
  color: #ff8429;
  font-size: 16px;
  text-decoration: underline;
}
.brand__slider {
  position: relative;
}
.brand__slider button {
  font-size: 26px;
  text-align: center;
  color: #dddddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.brand__slider button.main-slider-button-prev {
  margin-right: 5px;
}
.brand__slider button:hover {
  color: #ff8429;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .brand__slider button {
    display: none;
  }
}
.brand__slider .owl-prev {
  position: absolute;
  top: 50%;
  left: -65px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .brand__slider .owl-prev {
    left: -15px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .brand__slider .owl-prev {
    left: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .brand__slider .owl-prev {
    left: -25px;
  }
}
.brand__slider .owl-next {
  position: absolute;
  right: -65px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .brand__slider .owl-next {
    right: -15px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .brand__slider .owl-next {
    right: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .brand__slider .owl-next {
    right: -25px;
  }
}
@media (max-width: 575px) {
  .brand__slider .owl-nav {
    display: none;
  }
}

.brand-area-2 .owl-carousel .owl-item img {
  width: auto;
}

.trending__info h5 {
  font-size: 24px;
}
.trending__info p {
  font-size: 15px;
}
.trending__categories-content {
  background: #ffffff;
  padding: 25px 40px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
}
.trending__categories-content h6 {
  font-size: 20px;
  margin-bottom: 15px;
}
.trending__categories-content .smc-list ul li {
  margin-bottom: 6px;
}
.trending__categories-content .smc-list ul li:last-child {
  margin-bottom: 0;
}
.trending__categories-content .smc-list ul li a {
  font-size: 15px;
  color: #888888;
  font-weight: 500;
  transition: 0.3s;
}
.trending__categories-content .smc-list ul li a i {
  margin-right: 10px;
}
.trending__categories-content .smc-list ul li a:hover {
  color: #ff8429;
  margin-left: 5px;
}
.trending__categories-content .ct-view {
  color: #ff8429;
  font-size: 14px;
  text-decoration: underline;
}

@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .payment-image-2 {
    margin-top: 20px;
    text-align: center;
  }
}

/*----------------------------------------*/
/* 17. About css start
/*----------------------------------------*/
.about__title {
  font-size: 80px;
  color: #222;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .about__title {
    font-size: 50px;
  }
}
.about__title-pre {
  font-size: 16px;
  color: #666666;
  display: inline-block;
  margin-bottom: 5px;
}
.about__title-area p {
  font-size: 16px;
  color: #666666;
  padding: 0 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about__title-area p {
    padding: 0 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about__title-area p {
    padding: 0;
  }
}
.about__history-title {
  font-size: 36px;
  padding-left: 125px;
  margin-bottom: 40px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__history-title {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .about__history-title {
    font-size: 20px;
    padding-left: 0;
  }
}
.about__history-title span {
  font-weight: 400;
}
.about__history-title-pre {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff8429;
  position: relative;
  padding-left: 125px;
  margin-bottom: 13px;
  display: inline-block;
}
@media (max-width: 575px) {
  .about__history-title-pre {
    padding-left: 0;
  }
}
.about__history-title-pre::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 100px;
  height: 2px;
  transform: translateY(-50%);
  background: #ff8429;
}
@media (max-width: 575px) {
  .about__history-title-pre::after {
    display: none;
  }
}
.about__history-wrapper p {
  font-size: 14px;
  color: #555555;
}
.about__history-wrapper p.about__history-text {
  font-size: 16px;
  color: #222;
  margin-bottom: 30px;
}
.about__gallery-review {
  padding: 124px 200px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about__gallery-review {
    padding-top: 98px;
    padding-bottom: 150px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__gallery-review {
    padding: 80px 115px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__gallery-review {
    padding: 55px 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__gallery-review {
    padding: 70px 55px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__gallery-review {
    padding: 70px 75px;
  }
}
@media (max-width: 575px) {
  .about__gallery-review {
    padding: 50px 20px;
  }
}
.about__gallery-review h3 {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5px;
}
.about__gallery-review span {
  font-size: 12px;
  font-weight: 500;
  color: #555555;
  letter-spacing: 12px;
  display: inline-block;
  margin-bottom: 25px;
}
.about__gallery-review p {
  color: #b0b0b0;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .about__offer {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
.about__offer-content h3 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .about__offer-content h3 {
    font-size: 20px;
  }
}
.about__offer-content p {
  font-size: 14px;
  color: #fff;
  padding: 0 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about__offer-content p {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__offer-content p {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media (max-width: 575px) {
  .about__offer-content p {
    padding-right: 0;
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about__offer-content p br {
    display: none;
  }
}

.sec-wrapper h5 {
  color: #ff8429;
  letter-spacing: 1px;
  font-size: 19px;
  font-weight: 400;
}

.absection-title {
  font-size: 36px;
  margin-bottom: 40px;
}

.mb-8 {
  margin-bottom: 8px;
}

.single-contact-info {
  padding-top: 30px;
}
.single-contact-info:hover .contact-info-icon a {
  background-color: #ff8429;
  color: #ffffff;
}
.single-contact-info:last-child {
  border: none;
}

.contact-info-icon {
  margin-right: 20px;
}
.contact-info-icon a {
  height: 65px;
  width: 65px;
  background: #f3f3f3;
  display: inline-block;
  line-height: 65px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  color: #ff8429;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.contact-info-icon a:hover {
  background-color: #222;
  color: #ffffff;
}

.contact-info-text span {
  font-size: 14px;
  text-transform: uppercase;
  color: #cccccc;
  font-weight: 400;
  margin-bottom: 5px;
  display: inline-block;
}
.contact-info-text h5 {
  font-weight: 400;
  font-size: 20px;
}
@media (max-width: 575px) {
  .contact-info-text h5 {
    font-size: 16px;
  }
}

.map-area {
  line-height: 0;
}

#contact-map iframe {
  height: 555px;
  width: 100%;
  margin-bottom: -6px;
}

/* . Cta  css*/
.quality-area {
  border-top: 1px solid #e3e3e3;
}

.kquality-icon {
  height: 120px;
  width: 120px;
  line-height: 148px;
  text-align: center;
  background: #ff8429;
  border-radius: 50%;
  float: left;
  margin-right: 40px;
}
@media (max-width: 575px) {
  .kquality-icon {
    float: inherit;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kquality-icon {
    margin-bottom: 0px;
    float: left;
  }
}
.kquality-icon i {
  font-size: 55px;
  color: #ffffff;
  display: inline-block;
  margin-top: 10px;
}

.kquality-text-title {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
}

.kquality-text p {
  font-size: 16px;
  line-height: 2;
  margin-bottom: 0px;
}

.kquality-img {
  position: relative;
  display: inline-block;
}
.kquality-img:before, .kquality-img:after {
  height: 100%;
  width: 10px;
  top: 0px;
  left: 0px;
  background: #ff8429;
  position: absolute;
  content: "";
  z-index: 1;
}
.kquality-img:after {
  left: 10px;
  background: #ffffff;
}

/*----------------------------------------*/
/*  18. BLOG CSS START
/*----------------------------------------*/
.blog__slider .owl-nav {
  position: absolute;
  top: -60px;
  right: 0;
}
.blog__slider-2 {
  position: relative;
}
.blog__slider-2 button {
  font-size: 26px;
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 40px;
  text-align: center;
  border: 2px solid #dddddd;
  color: #dddddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.blog__slider-2 button.main-slider-button-prev {
  margin-right: 5px;
}
.blog__slider-2 button:hover {
  background-color: #000;
  border-color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .blog__slider-2 button {
    display: none;
  }
}
.blog__slider-2 .owl-prev {
  position: absolute;
  top: 50%;
  left: -65px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .blog__slider-2 .owl-prev {
    left: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .blog__slider-2 .owl-prev {
    left: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .blog__slider-2 .owl-prev {
    left: -25px;
  }
}
.blog__slider-2 .owl-next {
  position: absolute;
  right: -65px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .blog__slider-2 .owl-next {
    right: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .blog__slider-2 .owl-next {
    right: -40px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  .blog__slider-2 .owl-next {
    right: -25px;
  }
}
.blog__slider-3 {
  position: relative;
}
.blog__slider-3 .owl-nav {
  display: flex;
  position: absolute;
  top: -68px;
  right: 0;
}
.blog__item {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
}
.blog__item-2 {
  box-shadow: none;
}
.blog__tag {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #b9a16b;
  padding: 0 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (max-width: 575px) {
  .blog__tag {
    width: 80%;
  }
}
.blog__tag span {
  font-size: 11px;
  color: #ffffff;
  text-transform: uppercase;
}
.blog__content {
  padding: 35px 30px 25px;
}
.blog__content p {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}
.blog__content-3 {
  padding: 20px 0;
  padding-right: 30px;
}
.blog__title {
  font-size: 20px;
  font-weight: 600;
  color: #222;
  margin-bottom: 12px;
}
.blog__title a:hover {
  color: #b9a16b;
}
.blog__meta span {
  font-size: 14px;
  color: #999999;
  margin-right: 8px;
}
.blog__meta span a:hover {
  color: #b9a16b;
}
.blog__meta-2 span {
  color: #db3340;
}
.blog__meta-3 span {
  color: #ff8429;
  font-weight: 500;
}
.blog__meta-3 .blog-sm-author {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}
.blog__meta-3 .blog-sm-author::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #ff8429;
  right: -14px;
  bottom: 8px;
  border-radius: 50%;
}
.blog__author img {
  width: 20px;
  display: inline-block;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.blog__tpauthor-img img {
  width: auto;
  height: auto;
}
.blog__date {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 54px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 6px 0 8px;
}
.blog__date h5 {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 0;
}
.blog__date p {
  font-size: 12px;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px), (max-width: 575px) {
  .blog__wrapper {
    padding-right: 0;
  }
}

/* blog css */
.blog-wrap {
  position: relative;
  z-index: 3;
  margin-top: -291px;
}
.blog-wrap.blog-item {
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
  .blog-wrap {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.blog-title {
  font-size: 32px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-title {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .blog-title {
    font-size: 25px;
  }
}

.blog-thumb img {
  width: 100%;
}

.blog-video {
  position: relative;
}
.blog-video .play-btn {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 24px;
  color: #222;
  background: #ff8429;
  border-radius: 50%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.blog-video .play-btn:hover {
  background: #222;
  color: #ffffff;
}

.blog-meta {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
}
.blog-meta li {
  display: inline-block;
  margin-right: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-meta li {
    font-size: 14px;
  }
}
.blog-meta li:last-child {
  margin-right: 0;
}
.blog-meta li a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #686a6f;
}
.blog-meta li a:hover {
  color: #ff8429;
}
.blog-meta li a i {
  color: #ff8429;
  margin-right: 3px;
}

.blog-info {
  padding-top: 28px;
  border-top: 1px solid #e9ebee;
}

.blog-tag span,
.blog-category span {
  font-size: 16px;
  text-transform: capitalize;
  color: #222;
}
.blog-tag a:hover,
.blog-category a:hover {
  color: #222;
}

/* sidebar widget */
.sidebar__widget-title h4 {
  font-size: 24px;
  position: relative;
  padding-bottom: 12px;
}
.sidebar__widget-content .search {
  position: relative;
}
.sidebar__widget-content .search input {
  width: 100%;
  height: 70px;
  line-height: 70px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 20px;
  padding-right: 40px;
  background: #ff8429;
  color: #ffffff;
}
.sidebar__widget-content .search input::placeholder {
  color: #ffffff;
}
.sidebar__widget-content .search button {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #ffffff;
  background: transparent;
}
.sidebar__widget-content .cat-link ul li {
  margin-bottom: 15px;
}
.sidebar__widget-content .cat-link ul li:last-child {
  margin-bottom: 0;
}
.sidebar__widget-content .cat-link ul li a {
  position: relative;
  font-weight: 400;
  display: block;
}
.sidebar__widget-content .cat-link ul li a::after {
  position: absolute;
  content: "\f105";
  right: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
}
.sidebar__widget-content .cat-link ul li a:hover {
  color: #ff8429;
}
.sidebar__widget-content .cat-link ul li a:hover::after {
  color: #ff8429;
}
.sidebar__widget-content .tags a {
  font-size: 16px;
}
.sidebar__widget-content .tags a:hover {
  color: #ff8429;
}
.sidebar__widget-padding {
  padding: 45px 45px;
}

/* recent post */
.rc-text h6 {
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 0px;
}
.rc-text h6 a:hover {
  color: #ff8429;
}

.rc-thumb img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.rc-meta {
  font-size: 13px;
}

.user-rating ul li {
  display: inline-block;
}
.user-rating ul li a {
  color: #ff8429;
}

.post-rating ul li {
  display: inline-block;
}
.post-rating ul li a:hover {
  color: #222;
}

.post-date span {
  font-size: 14px;
  font-weight: 400;
  color: #ff8429;
}

.post-comments {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 42px;
  padding-bottom: 46px;
}

.comments-box {
  border-top: 1px solid #e3e3e3;
  padding: 30px 0;
}

.latest-comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}

.comments-avatar {
  float: left;
  margin-right: 20px;
}
.comments-avatar img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.comments-text {
  overflow: hidden;
  padding-top: 4px;
}

.comments-text p {
  font-size: 16px;
}

.comments-text p span {
  color: #282828;
}

.avatar-name {
  margin-bottom: 3px;
  overflow: hidden;
  position: relative;
}

.avatar-name h5 {
  font-size: 18px;
  margin-bottom: 0px;
  display: inline-block;
  color: #222;
}

.avatar-name span {
  color: #ff8429;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.reply-2 {
  font-weight: 600;
  font-size: 14px;
  color: #222;
}
.reply-2:hover {
  color: #ff8429;
}

.conatct-post-form .post-check {
  display: flex;
  align-items: center;
}
.conatct-post-form .post-check input {
  margin-right: 5px;
  width: auto;
  margin-bottom: 0;
  padding: 0;
}

.comments-text p {
  margin-bottom: 15px;
  margin-top: 8px;
  color: #5b5b5b;
}

.latest-comments li.children {
  margin-left: 100px;
}
@media (max-width: 575px) {
  .latest-comments li.children {
    margin-left: 15px;
  }
}

.load-comments {
  color: #ff8429;
  font-weight: 500;
}

.conatct-post-form input {
  border: 2px solid #f5f5f5;
  height: 55px;
  margin-bottom: 30px;
  padding: 0 30px;
  width: 100%;
  font-size: 14px;
  background: #f5f5f5;
}
.conatct-post-form input::placeholder {
  color: #bbbbbb;
}
.conatct-post-form input:focus {
  border-color: #ff8429;
}

.conatct-post-form textarea {
  border: 2px solid #f5f5f5;
  height: 175px;
  margin-bottom: 10px;
  padding: 15px 25px;
  width: 100%;
  font-size: 14px;
  resize: none;
  outline: none;
  background: #f5f5f5;
}
.conatct-post-form textarea::placeholder {
  color: #bbbbbb;
}
.conatct-post-form textarea:focus {
  border-color: #ff8429;
}

.post-rating {
  display: flex;
}

.blog__author {
  padding: 30px 40px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  -moz-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 575px) {
  .blog__author-content {
    margin-top: 30px;
  }
}
.blog__author-content h5 {
  font-size: 18px;
  margin-bottom: 10px;
}
.blog__author-content span {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 15px;
}
.blog__author-content p {
  margin-bottom: 0;
}
.blog__author-social {
  margin-bottom: 10px;
}
.blog__author-social ul li {
  display: inline-block;
}
.blog__author-social ul li:not(:last-child) {
  margin-right: 5px;
}
.blog__author-social ul li a:hover {
  color: #ff8429;
}

.blog__slider-d.owl-carousel .owl-item img {
  display: inherit;
  width: auto;
}

/*----------------------------------------*/
/*  19. TEAM CSS START
/*----------------------------------------*/
.tpteam__img {
  position: relative;
}

.tpteam__img img {
  max-width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  border-radius: 8px;
}

.tpteam:hover .tpteam__img img {
  -webkit-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -moz-filter: grayscale(0);
}

.tpteam__social {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  background: #ff8429;
  display: inline-block;
  padding: 16px 12px;
  margin-left: 30px;
  margin-right: 30px;
  transform: scale(0.9);
  transition: 0.3s;
}

.tpteam__social a {
  color: #222;
  font-size: 14px;
  margin: 0 6px;
}

.tpteam__text {
  padding-top: 25px;
}

.tpteam-title {
  font-size: 20px;
  font-weight: 600;
}

.tpteam-title a:hover {
  color: #ff8429;
}

.tpteam__text h5 {
  font-weight: 500;
  font-size: 18px;
  color: #666;
}

.tpteam:hover .tpteam__social {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

/*----------------------------------------*/
/*  20. cart CSS
/*----------------------------------------*/
/* 16. Cart */
.table-content table {
  background: #ffffff;
  border-color: #e4e4e4;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e4e4e4;
}

.table-content .product-quantity {
  float: none;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: #ff8429;
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th, .table-content table td {
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #dcdcdc;
}

.table td, .table th {
  border-top: 1px solid #e4e4e4;
}

.product-quantity > input {
  width: 80px;
  border-radius: 3px;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
  position: relative;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 50px;
  border: 2px solid #e4e4e4;
  padding: 0 15px;
  margin-right: 10px;
}
#coupon_code:focus {
  border-color: #ff8429;
}
#coupon_code::placeholder {
  color: #6f7172;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #e4e4e4;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #e4e4e4;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

/* 17. Checkout */
.coupon-accordion h3 {
  background-color: #f6f6f6;
  border-top: 3px solid #222;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}

.coupon-accordion span:hover, p.lost-password a:hover {
  color: #222;
}

.coupon-content {
  border: 1px solid #e4e4e4;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
  border: 1px solid #e4e4e4;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  box-shadow: none;
}
.coupon-info p.form-row-first input:focus, .coupon-info p.form-row-last input:focus {
  border-color: #ff8429;
}

.coupon-info p.form-row input[type=submit]:hover, p.checkout-coupon input[type=submit]:hover {
  background: #ff8429 none repeat scroll 0 0;
}

.coupon-info p.form-row input[type=checkbox] {
  position: relative;
  top: 2px;
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input, .coupon input[type=submit], .buttons-cart a, .coupon-info p.form-row input[type=submit] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type=text] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #e4e4e4;
  margin-bottom: 15px;
  outline: none;
  box-shadow: none;
}
p.checkout-coupon input[type=text]:focus {
  border-color: #ff8429;
}
p.checkout-coupon input[type=text]::placeholder {
  color: #6f7172;
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #e4e4e4;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #e4e4e4;
  padding: 0 10px;
  height: 50px;
}

.country-select .nice-select::after {
  margin-top: -5px;
}

.country-select label, .checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required, .checkout-form-list label span.required {
  color: red;
}

.country-select .nice-select {
  border: 1px solid #e4e4e4;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
  border-radius: 0;
}
.country-select .nice-select:focus {
  border: 1px solid #ff8429;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type=text], .checkout-form-list input[type=password], .checkout-form-list input[type=email] {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  box-shadow: none;
}
.checkout-form-list input[type=text]:focus, .checkout-form-list input[type=password]:focus, .checkout-form-list input[type=email]:focus {
  border-color: #ff8429;
}

.checkout-form-list input[type=text]::-moz-placeholder,
.checkout-form-list input[type=password]::-moz-placeholder,
.checkout-form-list input[type=email]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=text]::placeholder,
.checkout-form-list input[type=password]::placeholder,
.checkout-form-list input[type=email]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=checkbox] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: #6f7172;
}

.order-notes textarea {
  border: 1px solid #e4e4e4;
  height: 90px;
  padding: 15px;
  width: 100%;
  outline: none;
}
.order-notes textarea:focus {
  border-color: #ff8429;
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid #e4e4e4;
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #e4e4e4;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #e4e4e4;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th, .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
  color: #222;
  font-size: 18px;
  font-weight: 500;
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #e4e4e4;
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
}
.payment-method .accordion-button:not(.collapsed) {
  color: #222;
  background-color: #fff;
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  padding: 0;
  padding-bottom: 20px;
}
.payment-method .accordion-body p {
  margin-bottom: 0;
}
.payment-method .accordion-collapse {
  border: none;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #ff8429 none repeat scroll 0 0;
}

.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #e4e4e4;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #e4e4e4;
  font-size: 1.2rem;
}


.order-button-payment button {
  width: 100%;
}

.cart-plus-minus {
  width: 180px;
}

.cart-plus-minus input {
  border: 0px;
  outline: 0px;
  background: none;
  font-weight: 400;
  color: #222;
  font-size: 14px;
  display: inline-block;
  height: 45px;
  list-style: 45px;
  padding: 0 50px;
  width: 180px;
  border: 1px solid #ebebeb;
  text-align: center;
  border-radius: 0;
}

.cart-plus-minus .qtybutton {
  font-size: 20px;
  color: #222;
  display: inline-block;
  position: absolute;
  top: 50%;
  height: 22px;
  width: 22px;
  background: transparent;
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  border-radius: 0;
  transform: translateY(-50%);
}

.cart-plus-minus .dec {
  left: 20px;
}

.cart-plus-minus .inc {
  right: 20px;
}

/*----------------------------------------*/
/*  21. cart CSS
/*----------------------------------------*/
.cartmini__area .modal.show .modal-dialog {
  transform: none !important;
}
.cartmini__area .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(100px, 0px);
}
.cartmini__area .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: #ffffff;
}
.cartmini__area .modal-content {
  border: 0;
}
.cartmini__wrapper {
  padding: 65px 65px;
  padding-top: 50px;
}
@media (max-width: 575px) {
  .cartmini__wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.cartmini__top {
  padding-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
}
.cartmini__top h4 {
  font-size: 18px;
  margin-bottom: 0;
}
.cartmini__close-btn {
  font-size: 24px;
  color: #222;
  background: transparent;
  border: 0;
  outline: 0;
}
.cartmini__item {
  padding: 20px 0;
  padding-right: 30px;
  border-bottom: 1px solid #f5f5f5;
}
.cartmini__item:last-child {
  border: none;
}
.cartmini__thumb img {
  width: 80px;
}
.cartmini__title {
  font-size: 15px;
  color: #222;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;
}
.cartmini__title a:hover {
  color: #b9a16b;
}
.cartmini__remove {
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 13px;
  color: #bebdbd;
  height: 17px;
  width: 17px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #bebdbd;
  border-radius: 50%;
}
.cartmini__price .price {
  font-size: 13px;
  color: #222;
}
.cartmini__total {
  padding: 20px 0;
  margin-bottom: 20px;
  border-top: 1px solid #f5f5f5;
}
.cartmini__total h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
  color: #222;
}
.cartmini__total span {
  font-size: 18px;
  color: #222;
}
.cartmini__list {
  height: 575px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cartmini__list {
    height: 540px;
  }
}
@media (max-width: 575px) {
  .cartmini__list {
    height: 475px;
  }
}

/*----------------------------------------*/
/*  06. Sign CSS START
/*----------------------------------------*/
.sign__wrapper {
  padding: 50px 70px;
  padding-bottom: 45px;
  margin: 0 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 40px 80px 0px rgba(2, 2, 26, 0.14);
  -moz-box-shadow: 0px 40px 80px 0px rgba(2, 2, 26, 0.14);
  box-shadow: 0px 40px 80px 0px rgba(2, 2, 26, 0.14);
  z-index: 11;
}
@media (max-width: 575px) {
  .sign__wrapper {
    margin: 0;
    padding: 30px 20px;
    padding-bottom: 25px;
  }
}
.sign__header {
  padding: 0 35px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__header {
    padding: 0 10px;
  }
}
@media (max-width: 575px) {
  .sign__header {
    padding: 0px;
  }
}
.sign__header p {
  margin-bottom: 0;
  color: #7a797f;
  position: relative;
  padding: 0 40px;
  display: inline-block;
}
.sign__header p span {
  position: absolute;
  top: 40%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sign__header p span:last-child {
  left: auto;
  right: 0;
}
.sign__header p a:hover {
  color: #ff8429;
}
.sign__social {
  display: block;
  height: 50px;
  background: #f2f2f2;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative;
  line-height: 48px;
  padding-left: 23px;
  z-index: 1;
  overflow: hidden;
  font-size: 16px;
}
.sign__social::after {
  position: absolute;
  content: "";
  left: -40%;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 220px;
  width: 220px;
  background: #3360bd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__social::after {
    left: -50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__social::after {
    left: -35%;
  }
}
@media (max-width: 575px) {
  .sign__social::after {
    left: -35%;
  }
}
.sign__social i {
  color: #ffffff;
  font-size: 16px;
  margin-right: 50px;
}
@media (max-width: 575px) {
  .sign__social i {
    margin-right: 35px;
  }
}
.sign__social:hover {
  color: #ffffff;
}
.sign__social:hover::after {
  width: 100%;
  height: 150%;
  left: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.sign__social.g-plus::after {
  background: #e93e30;
}
.sign__form h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 11px;
}
.sign__input {
  position: relative;
}
.sign__input input {
  width: 100%;
  height: 60px;
  line-height: 52px;
  padding: 0 50px;
  font-size: 14px;
  border: 2px solid transparent;
  background: #f5f5f5;
  color: #222;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.sign__input input::placeholder {
  color: #7a797f;
}
.sign__input input:focus {
  outline: none;
  background: #ffffff;
  border-color: #ff8429;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(8, 0, 42, 0.2);
  -moz-box-shadow: 0px 1px 4px 0px rgba(8, 0, 42, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(8, 0, 42, 0.2);
}
.sign__input i {
  position: absolute;
  top: 51%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 25px;
  font-size: 14px;
  color: #7a797f;
}
.sign__agree input {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  display: block;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid #7a797f;
  outline: none;
  margin-top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.sign__agree input:checked {
  position: relative;
  background-color: #ff8429;
  border-color: transparent;
}
.sign__agree input:checked::after {
  box-sizing: border-box;
  content: "\f00c";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 10px;
  color: #ffffff;
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sign__agree input:hover {
  cursor: pointer;
}
.sign__agree label {
  padding-left: 10px;
  font-size: 14px;
}
.sign__agree label:hover {
  cursor: pointer;
}
.sign__agree label a {
  color: #333;
  font-weight: 500;
}
.sign__agree label a:hover {
  color: #ff8429;
}
.sign__forgot a {
  color: #7a797f;
}
.sign__forgot a:hover {
  color: #ff8429;
}
.sign__new p {
  color: #7a797f;
  margin-bottom: 0;
}
.sign__new p a {
  color: #ff8429;
}

/*----------------------------------------*/
/*  22. FOOTER CSS START
/*----------------------------------------*/
.footer__top-space {
  padding-top: 30px;
}
.footer__top-space-2 {
  padding-top: 60px;
}
.footer__widget.footer-col-1 {
  padding-right: 20px;
}
.footer__widget.footer-col-2 {
  padding-left: 60px;
}
@media (max-width: 575px) {
  .footer__widget.footer-col-2 {
    padding-left: 0;
  }
}
.footer__widget.footer-col-3 {
  padding-left: 40px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget.footer-col-3 {
    padding-left: 0;
  }
}
.footer__widget.footer-col-4 {
  padding-left: 65px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer__widget.footer-col-4 {
    padding-left: 0;
  }
}
.footer__widget.footer-col-5 {
  padding-left: 65px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer__widget.footer-col-5 {
    padding-left: 0;
  }
}
.footer__widget-title {
  font-size: 15px;
  font-weight: 600;
  color: #b9a16b;
  text-transform: capitalize;
  margin-bottom: 27px;
}
.footer__widget-title-2 {
  color: #ffffff;
}
.footer__widget-title-3 {
  color: #ff8429;
}
.footer__widget-border {
  position: relative;
}
.footer__widget-border::after {
  position: absolute;
  content: "";
  right: -40px;
  top: 10px;
  width: 1px;
  height: 180px;
  background-color: #3a3a3a;
}
@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .footer__widget-border::after {
    display: none;
  }
}
.footer__widget-border-2::after {
  left: -80px;
  height: 180px;
}
.footer__subscribe p {
  font-size: 22px;
  color: #ffffff;
  line-height: 32px;
  margin-bottom: 15px;
}
.footer__subscribe-2 p {
  font-size: 13px;
  color: #bbbbbb;
}
.footer__subscribe-2 p a {
  color: #ffffff;
}
.footer__subscribe-input {
  position: relative;
}
.footer__subscribe-input input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  padding-right: 35px;
  background-color: #ffffff;
  outline: 0;
  border: 0;
  font-size: 13px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe-input input {
    padding: 0 15px;
    padding-right: 45px;
  }
}
.footer__subscribe-input input::placeholder {
  color: #6c6c6c;
}
.footer__subscribe-input button {
  position: absolute;
  top: 50%;
  padding: 7px 20px;
  right: 0px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: #bbbbbb;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe-input button {
    right: 17px;
  }
}
.footer__subscribe-3 {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .footer__subscribe-3 {
    display: block;
  }
}
.footer__subscribe-3 p {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .footer__subscribe-3 p {
    margin-bottom: 20px;
  }
}
.footer__contact-phone {
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
}
.footer__contact-email p {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}
.footer__contact-email-2 p {
  color: #bbbbbb;
  font-weight: 500;
}
.footer__opening p {
  font-size: 12px;
  color: #999999;
  margin-bottom: 0;
}
.footer__social ul li {
  display: inline-block;
  margin-right: 15px;
}
.footer__social ul li a {
  font-size: 17px;
  display: inline-block;
  color: #b9a16b;
}
.footer__social ul li a:hover {
  color: #ffffff;
}
.footer__social-2 ul li a {
  color: #db3340;
}
.footer__social-3 ul li a {
  color: #ff8429;
}
.footer__links ul li {
  line-height: 1;
  margin-bottom: 15px;
}
.footer__links ul li a {
  font-size: 13px;
  color: #ffffff;
  position: relative;
}
.footer__links ul li a::after {
  position: absolute;
  content: "";
  left: auto;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #ffffff;
}
.footer__links ul li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
.footer__links-2 ul li a {
  color: #bbbbbb;
}
.footer__links-2 ul li a:hover {
  color: #ffffff;
}
.footer__offer-inner {
  border-top: 1px solid rgba(255, 255, 255, 0.14);
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}
.footer__offer-inner-2 {
  padding: 20px 0;
}
.footer__offer-inner-2 p {
  font-size: 15px;
  font-weight: 500;
  margin-right: 50px;
}
.footer__offer-inner-2 input {
  background: #ffffff;
  border-radius: 3px;
}
.footer__offer-inner-2 button {
  color: #282828;
}
.footer__offer-content p {
  font-size: 13px;
  margin-bottom: 0;
  color: #f3f3f3;
}
.footer__offer-content p a {
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}
.footer__offer-content p a::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #f3f3f3;
}
.footer__offer-content p:first-child {
  position: relative;
  padding-right: 20px;
  margin-right: 20px;
  padding-top: 5px;
  padding-bottom: 6px;
}
.footer__offer-content p:first-child::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 18px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.footer__bottom-wrapper {
  padding-top: 18px;
  padding-bottom: 18px;
}
.footer__bottom-links ul li {
  display: inline-block;
  margin-right: 15px;
}
.footer__bottom-links ul li a {
  font-size: 13px;
  color: #999999;
}
.footer__bottom-links ul li a:hover {
  color: #ffffff;
}
.footer__bottom-2-wrapper {
  border-top: 1px solid #3a3a3a;
}
.footer__copyright p {
  margin-bottom: 0;
}
.footer__copyright p a:hover {
  color: #ffffff;
}
.footer__info .sm-contact-info {
  display: flex;
}
@media (max-width: 575px) {
  .footer__info .sm-contact-info {
    display: block;
  }
}
.footer__info .sm-contact-info .sm-item {
  margin-right: 15px;
}
.footer__info .sm-contact-info .sm-item span {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  color: #bbbbbb;
}
.footer__info .sm-contact-info .sm-item span a {
  font-weight: 400;
  color: #bbbbbb;
  margin-left: 5px;
}

.logo{
  width: 190px;
}
.cat-btn {
    display: inline-block;
    padding: 0 25px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    font-weight: 500;
    background: #ff7008;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.product__price .price {
    font-size: 16px;
    color: #b42d72;
    font-weight: 600;
}
.product__sale-price .price.new-price {
    font-size: 18px;
    font-weight: 600;
    color: #ba3d7d;
}
.category__quantity {
    font-size: 13px;
    font-weight: 500;
    color: #ba3d7d;
}
.product__sale-wrapper {
    padding: 30px 30px 35px;
    border: 2px solid #ba3d7d;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.section__title::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background-color: #ba3d7d;
}
.features__icon i {
    font-size: 45px;
    color: #01adeb;
}
.blog__tag {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
     background: linear-gradient(135deg,rgb(48 101 162) 0,rgb(180 45 114) 100%);
    padding: 0 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.header__social ul li a i {
    color: #2dbdea;
}
.header__top-info{
  display: flex;
}
.header__top-info p {
    padding: 5px 15px 5px 0px;
    color: #fff;
    font-weight: 300;
    font-size: 13px;
}
p {
  font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #888888;
    margin-bottom: 0px;
    line-height: 26px;
}
.dd{
    width: 18px;
    margin: 0 0px 2px 0;
}
.asas{
  font-size: 12px!important;
    color: #ffdf99!important;
}
.top-ico{
  width: 20px;
}
.header__top-info p {
    padding: 5px 10px 5px 0px;
    color: #fff;
    font-weight: 300;
    font-size: 13px;
}
.header__top-info ul li::after {
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 30px;
    background-color: rgb(255 255 255 / 37%);
}
.sticky {
  position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    background: #fafafa;
    box-shadow: 0 1px 0 0 #ecedeb;
}

.sticky + .content {
  padding-top: 102px;
}
.por img{
  height: 527px;
}
.product__action ul li a {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background:#ff7008 /*linear-gradient(135deg,rgb(48 101 162) 0,rgb(180 45 114) 100%)*/;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);
}

.product__sale-flash span {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    height: 24px;
    line-height: 24px;
    padding: 0 14px;
    background: linear-gradient(135deg,rgb(48 101 162) 0,rgb(180 45 114) 100%);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
/*.product__thumb img{
  width: 240px!important;
  height: 240px;
}*/
.footer-bg {
    background:#141316 /*linear-gradient(135deg,rgb(48 101 162) 0,rgb(180 45 114) 100%)*/;
}
.footer__bottom-links ul li a {
    font-size: 13px;
    color: #ffffff;
}
.footer__copyright p{
  color: #fff;
}
.footer__opening p {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 0;
}
.footer__widget-title {
    font-size: 16px;
    font-weight: 600;
    color: #e0e0e0;
    text-transform: uppercase;
    margin-bottom: 27px;
}
.footer__social a{
  background: #fff;
    height: 25px;
    width: 25px;
    text-align: center;
    border-radius: 17px;
}
.footer__social ul li a {
    font-size: 17px;
    display: inline-block;
    color: #000000;
}
.footer__social{
  margin-top: 15px;
}
.side-submenu nav ul{
  display: flex;
}
.side-submenu {
    background: #ffffff;
    position: absolute;
    left: -153px;
    top: 120%;
    min-width: 960px !important;
    z-index: 111;
    visibility: hidden;
    opacity: 0;
    width: 100%;
}
.side-submenu ul li > a {
    display: inline-block;
    width: 100%;
    color: #888888;
    padding: 14px 15px;
    padding-bottom: 10px;
}
.side-submenu ul li.has-dropdown > a::after {
    position: absolute;
    content: ""!important;
    font-weight: 700;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    color: #888888;
}
.side-submenu ul li .mega-menu {
    position: absolute;
    left: 0%!important;
    top: 50px;
     width: 1176px; 
    background: #ffffff;
    z-index: 1;
    padding: 25px 25px;
    padding-bottom: 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    -webkit-box-shadow: 4.316px 4.168px 20px 0px rgb(0 0 0 / 10%);
    -moz-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 15px 15px 20px 0px rgb(0 0 0 / 10%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.mega-item{
  display: table-caption!important;
}
.side-submenu ul li .mega-menu li .mega-item li a {
    font-size: 15px;
    color: #888888;
    margin-bottom: 0;
    position: relative;
    display: inline-block;
    width: 220px;
}
.side-submenu ul li .submenu {
    position: absolute;
    top: 50px;
    left: 0%!important;
    width: 540px;
}
.side-submenu ul li .submenu li > a {
    width: 100%;
    display: block;
    padding: 15px 15px;
    text-transform: capitalize;
    border-bottom: none;
}
.side-submenu ul .has-dropdown:hover > a {
    color: #fff;
    background: linear-gradient(135deg,rgb(48 101 162) 0,rgb(180 45 114) 100%);
}




.widget-category-list {
  max-height: 500px;
  overflow: scroll;
}

.search-typebox{
  position: absolute;
  z-index: 1;
}

.search-typebox .ant-select-selector {
  height: 40px !important;
  padding: 4px 13px 0px 15px !important;
}

.auto-search .ant-select-auto-complete input {
  padding-left: 105px !important;
}

.widget-category-list .ant-skeleton-element{
  width: 100%;
}

.widget-category-list {
  min-height: 50vh;
}

@media only screen and (min-width: 380px) and (max-width: 767px), (max-width: 575px) {

    .product__item-slider .owl-nav{display: block !important; right: -5px !important;}
    .product__area{padding: 0px 0px !important;}
    .footer__widget-title{    margin-bottom: 6px;      margin-top: 25px;}
    .ant-pagination-item{    min-width: 20px;      height: 25px; line-height: 25px;}
    .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{    min-width: 25px;
      height: 25px; line-height: 25px;}
}



@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-slider-pagination {
    right: 120px;
  }
  .slider__height {
    min-height: 400px;
  }
}





