/*.product-item {
    position: absolute;
    right: 0px;
    display: none;
    top: 48px;
    font-size: 12px;
    width: 500px;
    z-index: 9999;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #443f3f;
}
.shopping-item:hover .product-item{
    display: inline-block;
}*/

.shopping-item:hover .product-item{
    display: inline-block;
}
.ant-drawer-body{padding: 0px;}
.Cart-box .table td .p_name{
    line-height: 18px;
    color: #000;
    font-size: 14px;}
    .Cart-box .table td .p_remove {background: #000;
        color: #fff;
        text-align: center;
        margin: 5px auto;
        padding: 2px;
        border-radius: 5px;
        font-size: 13px;
      }

.Cart-box .table td  .ant-input-number.form-control{padding: 0.1rem 0.4rem;}
.cart-link-box .btn{width: 100%; border-radius: 0;background: #000;color: #fff;text-transform: uppercase; font-size: 18px;}
ul.attribute-ul li {
    display: inline-block;
    width: auto;
    border: 1px solid;
    text-align: center;
    margin-right: 15px;
    padding: 5px;
    min-width: 50px;
    font-weight: bold;
}
ul.attribute-ul li.active {
    background: green;
    color: #fff;
}
ul.attribute-ul {
    display: inline-block;
    margin: 0px;
    padding: 0px;
}

.attribute-box label {
    width: 100px;
}

ul.cart-variant-list li {
    display: inline-block;
    margin-right: 15px;
    font-size: 12px;
}

.shopping-item a {
    color: #000;
}

.cart-amunt {
    color: #000;
    font-weight: 700;
}
.shopping-item {
    border: 1px solid #ddd;
    float: right;
    font-size: 18px;
    margin-top: 0;
    padding: 10px;
    position: relative;
    z-index: 999;
    border-radius: 5px;
    color: #000;
}

.header__social {
    margin-top: 5px;
}
.header__social li a {
    color: #fff !important;
}
.header-search-box .auto-search{
    width: 100%;
}